import React from 'react';
import { useDispatch } from 'react-redux';

import { Label, Input } from 'reactstrap';
import { Upload, Trash3 } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import './shared.scss';
import { actionCreators } from 'store/globalStore/actionCreators';

interface FilesUploadProps {
    title?: string;
    files: File[];
    onChange: (files: File[]) => void;
}
export function FileUploadField(props: FilesUploadProps) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isFilePicked, setIsFilePicked] = React.useState(
        props.files.length > 0
    );
    const SIZE_LIMIT = 1024;
    const ACCEPTED_TYPES = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
        'application/vnd.oasis.opendocument.presentation',
        'application/vnd.oasis.opendocument.spreadsheet',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/rtf',
    ];

    function changeHandler(event: any) {
        if (event.target.files[0].size / 1024 > SIZE_LIMIT) {
            dispatch(
                actionCreators.setMessage({
                    status: 500,
                    title: '',
                    message: t('warnings.imageSizeWarning', {
                        size: 1,
                    }),
                })
            );
        } else if (!ACCEPTED_TYPES.includes(event.target.files[0]['type'])) {
            dispatch(
                actionCreators.setMessage({
                    status: 500,
                    title: '',
                    message: t('warnings.fileShouldBeDocument'),
                })
            );
        } else {
            props.onChange([...props.files, event.target.files[0]]);
            setIsFilePicked(true);
        }
    }

    function removeFile(name: string) {
        props.onChange(props.files.filter((file) => file.name !== name));
        if (props.files.length === 0) {
            setIsFilePicked(false);
        }
    }

    function prepareFileName(name: string) {
        if (name.length > 50) {
            return '...' + name.slice(-27);
        } else {
            return name;
        }
    }

    return (
        <>
            <div className="upload-file-container">
                <Label
                    for="file-upload"
                    className="btn btn-outline-primary btn-block"
                >
                    <Upload height={24} />
                    {props.title?.toUpperCase()}
                </Label>
                <Input
                    id="file-upload"
                    type="file"
                    name="file"
                    onChange={changeHandler}
                    multiple
                />
                {isFilePicked
                    ? props.files.map((file) => (
                          <div className="file-item" key={file.name}>
                              {prepareFileName(file.name)}{' '}
                              <Trash3
                                  className="file-remove-btn"
                                  onClick={() => removeFile(file.name)}
                              />
                          </div>
                      ))
                    : null}
            </div>
        </>
    );
}
