import React, { useState, useRef } from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import './selects.scss';

export interface SelectOption {
    value: any;
    text: string;
    icon?: string;
}

export default function BaseSelect(props: {
    title: string;
    onChange: any;
    options: any[];
    value: any;
}) {
    const [isOnFocus, setIsOnFocus] = useState(false);
    const select = useRef<any>(null);
    const selectYOffset = useRef(0);

    const DEFAULT_OPTION_HEIGHT = 36;
    const selectHeight = props.options.length * DEFAULT_OPTION_HEIGHT + 50;

    function prepareShow() {
        if (props.value && props.value.text) {
            return (
                <div style={{ color: 'black' }}>
                    {props.value.icon && (
                        <img
                            src={props.value.icon}
                            alt={`option-label-${Math.random}`}
                            style={{ marginRight: 10 }}
                        />
                    )}
                    {props.value.text}
                </div>
            );
        } else {
            return props.title;
        }
    }

    function prepareOptionsStyles() {
        const shouldBeTop =
            selectYOffset.current + selectHeight > window.innerHeight;
        const display = isOnFocus ? 'block' : 'none';
        if (shouldBeTop) {
            return {
                display: display,
                bottom: '80%',
            };
        } else {
            return {
                display: display,
                top: 'calc(100% + 10px)',
            };
        }
    }

    function option(option: SelectOption) {
        return (
            <div
                className={`d-flex justify-content-between app-option ${
                    option.value === props.value?.value
                        ? 'app-option-selected'
                        : ''
                }`}
                key={`select-option-${Math.random()}`}
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOnFocus(false);
                    props.onChange(option);
                }}
            >
                <div>
                    {option.icon && (
                        <img
                            src={option.icon}
                            alt={`option-${Math.random}`}
                            className="option-icon"
                        />
                    )}
                    <span>{option.text}</span>
                </div>
            </div>
        );
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setIsOnFocus(false)}>
            <div
                className={
                    isOnFocus ? 'form-select form-select-focus' : 'form-select'
                }
                ref={select}
                onClick={() => {
                    if (!isOnFocus) {
                        selectYOffset.current =
                            select.current?.getBoundingClientRect().y;
                    }
                    setIsOnFocus(!isOnFocus);
                }}
            >
                {prepareShow()}
                <div
                    className="form-select form-select-options-container"
                    style={prepareOptionsStyles()}
                >
                    {props.options.map((x: any) => option(x))}
                </div>
            </div>
        </OutsideClickHandler>
    );
}
