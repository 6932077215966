import {
    RhythmicGymnasticsAppartType,
    RhytmicGymnasticsEventParticipiant,
} from './enums';
import i18n from 'config/language/i118n';
import images from 'assets/images';
import { RhythmicGymnasticsAppart } from 'components/events/interfaces';
import { SelectOption } from 'components/events/newEvent/components/selects/BaseSelect';

export const environmentVariables = {
    baseURL: process.env.REACT_APP_API,
    facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
};

export const individualAppsOptions: RhythmicGymnasticsAppart[] = [
    {
        value: RhythmicGymnasticsAppartType.WithoutApparatus,
        text: i18n.t('words.withoutApparatus'),
        icon: images.icons.noApp,
        count: 0,
    },
    {
        value: RhythmicGymnasticsAppartType.ByChoice,
        text: i18n.t('words.byChoice'),
        icon: images.icons.choseApp,
        count: 0,
    },
    {
        value: RhythmicGymnasticsAppartType.Rope,
        text: i18n.t('words.rope'),
        icon: images.icons.ropeApp,
        count: 0,
    },
    {
        value: RhythmicGymnasticsAppartType.Hoop,
        text: i18n.t('words.hoop'),
        icon: images.icons.hoopApp,
        count: 0,
    },
    {
        value: RhythmicGymnasticsAppartType.Ball,
        text: i18n.t('words.ball'),
        icon: images.icons.ballApp,
        count: 0,
    },
    {
        value: RhythmicGymnasticsAppartType.Clubs,
        text: i18n.t('words.clubs'),
        icon: images.icons.clubsApp,
        count: 0,
    },
    {
        value: RhythmicGymnasticsAppartType.Ribbon,
        text: i18n.t('words.ribbon'),
        icon: images.icons.ribbonApp,
        count: 0,
    },
];

export const rhytmicGymnasticsEventParticipiantOptions: SelectOption[] = [
    {
        value: RhytmicGymnasticsEventParticipiant.Gymnast,
        text: i18n.t('words.gymnast'),
    },
    {
        value: RhytmicGymnasticsEventParticipiant.Coach,
        text: i18n.t('words.coach'),
    },
    {
        value: RhytmicGymnasticsEventParticipiant.Arbiter,
        text: i18n.t('words.arbiter'),
    },
    {
        value: RhytmicGymnasticsEventParticipiant.Manager,
        text: i18n.t('words.manager'),
    },
    {
        value: RhytmicGymnasticsEventParticipiant.MedicalStaff,
        text: i18n.t('words.medicalStaff'),
    },
    {
        value: RhytmicGymnasticsEventParticipiant.Spectator,
        text: i18n.t('words.spectator'),
    },
    {
        value: RhytmicGymnasticsEventParticipiant.Other,
        text: i18n.t('words.other'),
    },
];

export const rhytmicGymnasticsEventParticipiantMultipleOptions: SelectOption[] =
    [
        {
            value: RhytmicGymnasticsEventParticipiant.Gymnast,
            text: i18n.t('words.gymnasts'),
        },
        {
            value: RhytmicGymnasticsEventParticipiant.Coach,
            text: i18n.t('words.coaches'),
        },
        {
            value: RhytmicGymnasticsEventParticipiant.Arbiter,
            text: i18n.t('words.arbiters'),
        },
        {
            value: RhytmicGymnasticsEventParticipiant.Manager,
            text: i18n.t('words.managers'),
        },
        {
            value: RhytmicGymnasticsEventParticipiant.MedicalStaff,
            text: i18n.t('words.medicalStaff'),
        },
        {
            value: RhytmicGymnasticsEventParticipiant.Spectator,
            text: i18n.t('words.spectators'),
        },
        {
            value: RhytmicGymnasticsEventParticipiant.Other,
            text: i18n.t('words.others'),
        },
    ];

export const localStorageVariables = {
    isUserAuth: 'EST-Events-is-user-auth',
};
