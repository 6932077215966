import checkEmailIcon from './check-email-icon.svg';
import facebookCircleBtn from './facebook-circle-button.svg';
import checkedCircleSuccess from './confirmed-circle-success.svg';
import checkedCircleError from './confirmed-circle-error.svg';
import noApp from './no-app.svg';
import choseApp from './chose-app.svg';
import clubsApp from './clubs-app.svg';
import hoopApp from './hoop-app.svg';
import ribbonApp from './ribbon-app.svg';
import ropeApp from './rope-app.svg';
import ballApp from './ball-app.svg';
import optionMinus from './option-minus.svg';
import optionPlus from './option-plus.svg';
import attentionIcon from './attention-icon.svg';
import closeCross from './close-cross.svg';
import ascArrow from './asc-arrow.svg';
import descArrow from './desc-arrow.svg';
import disabledArrow from './disabled-arrow.svg';
import teamIcon from './team-icon.svg';

const icons = {
    checkEmailIcon,
    facebookCircleBtn,
    checkedCircleSuccess,
    checkedCircleError,
    noApp,
    choseApp,
    clubsApp,
    hoopApp,
    ribbonApp,
    ropeApp,
    ballApp,
    optionMinus,
    optionPlus,
    attentionIcon,
    closeCross,
    ascArrow,
    descArrow,
    disabledArrow,
    teamIcon,
};

export default icons;
