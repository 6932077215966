import React from 'react';

export default function PrivacyPolicy() {
    return (
        <>
            <p>
                EST Events ("us", "we", or "our") operates the
                https://est-events.com website (the "Service").
            </p>
            <h2>What information do we collect?</h2>
            <p>
                We collect several different types of information for various
                purposes to provide and improve our Service to you.
            </p>
            <ul>
                <li>
                    information about your computer and about your visits to and
                    use of this website (including your IP address, geographical
                    location, browser type and version, operating system,
                    referral source, length of visit, page views and website
                    navigation);
                </li>
                <li>
                    information relating to any transactions carried out between
                    you and us on or in relation to this website;
                </li>
                <li>
                    information that you provide to us for the purpose of
                    registering with us (including your name, birth date, your
                    email address, gender, );
                </li>
                <li>
                    nformation that you provide to us for the purpose of to use
                    our website services, email notifications and/or
                    newsletters;
                </li>
                <li>any other information that you choose to send to us;</li>
            </ul>
            <h3>Usage Data</h3>
            <p>
                We may also collect information how the Service is accessed and
                used ("Usage Data"). This Usage Data may include information
                such as your computer's Internet Protocol address (e.g. IP
                address), browser type, browser version, the pages of our
                Service that you visit, the time and date of your visit, the
                time spent on those pages, unique device identifiers and other
                diagnostic data.
            </p>
            <h3>Tracking & Cookies Data</h3>
            <p>
                We use cookies and similar tracking technologies to track the
                activity on our Service and hold certain information.
            </p>
            <p>
                Cookies are files with small amount of data which may include an
                anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your device. Tracking technologies
                also used are beacons, tags, and scripts to collect and track
                information and to improve and analyze our Service.
            </p>
            <p>
                You can instruct your browser to refuse all cookies or to
                indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our
                Service.
            </p>
            <p>Examples of Cookies we use:</p>
            <ul>
                <li>
                    <b>Session Cookies.</b> We use Session Cookies to operate
                    our Service.
                </li>
                <li>
                    <b>Preference Cookies.</b> We use Preference Cookies to
                    remember your preferences and various settings.
                </li>
                <li>
                    <b>Security Cookies.</b> We use Security Cookies for
                    security purposes.
                </li>
            </ul>
            <h2>Use of Data</h2>
            <p>EST Events uses the collected data for various purposes:</p>
            <ul>
                <li>To provide and maintain the Service</li>
                <li>To notify you about changes to our Service</li>
                <li>
                    To allow you to participate in interactive features of our
                    Service when you choose to do so
                </li>
                <li>To provide customer care and support</li>
                <li>
                    To provide analysis or valuable information so that we can
                    improve the Service
                </li>
                <li>To monitor the usage of the Service</li>
                <li>To detect, prevent and address technical issues</li>
            </ul>
            <h2>Transfer Of Data</h2>
            <p>
                Your information, including Personal Data, may be transferred to
                — and maintained on — computers located outside of your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ than those from your
                jurisdiction.
            </p>
            <p>
                If you are located outside Slovakia and choose to provide
                information to us, please note that we transfer the data,
                including Personal Data, to Slovakia and process it there.
            </p>
            <p>
                Your consent to this Privacy Policy followed by your submission
                of such information represents your agreement to that transfer.
            </p>
            <p>
                EST Events will take all steps reasonably necessary to ensure
                that your data is treated securely and in accordance with this
                Privacy Policy and no transfer of your Personal Data will take
                place to an organization or a country unless there are adequate
                controls in place including the security of your data and other
                personal information.
            </p>
            <h2>Disclosure Of Data</h2>
            <h3>Legal Requirements</h3>
            <p>
                EST Events may disclose your Personal Data in the good faith
                belief that such action is necessary to:
            </p>
            <ul>
                <li>To comply with a legal obligation</li>
                <li>
                    To protect and defend the rights or property of EST Events
                </li>
                <li>
                    To prevent or investigate possible wrongdoing in connection
                    with the Service
                </li>
                <li>
                    To protect the personal safety of users of the Service or
                    the public
                </li>
                <li>To protect against legal liability</li>
            </ul>
            <h2>Security Of Data</h2>
            <p>
                The security of your data is important to us, but remember that
                no method of transmission over the Internet, or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
            </p>
            <h2>Service Providers</h2>
            <p>
                We may employ third party companies and individuals to
                facilitate our Service ("Service Providers"), to provide the
                Service on our behalf, to perform Service-related services or to
                assist us in analyzing how our Service is used.
            </p>
            <p>
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
            </p>
            <h2>Links To Other Sites</h2>
            <p>
                Our Service may contain links to other sites that are not
                operated by us. If you click on a third party link, you will be
                directed to that third party's site. We strongly advise you to
                review the Privacy Policy of every site you visit.
            </p>
            <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
            </p>
            <h2>Third party websites</h2>
            <p>
                The website contains links to other websites. We are not
                responsible for the privacy policies or practices of third party
                websites.
            </p>
            <h2>Changes To This Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
            </p>
            <p>
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update the
                "effective date" at the top of this Privacy Policy.
            </p>
            <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
            </p>
            <h2>Contact Us</h2>
            <p>
                If you have any questions about this Privacy Policy, please
                contact us by{' '}
                <a href="mailto:webmaster@example.com">
                    esteventsukraine@gmail.com
                </a>
            </p>
        </>
    );
}
