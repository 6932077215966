import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Button,
    FormGroup,
    Form,
    FormFeedback,
    Label,
    Input,
    Row,
    Col,
} from 'reactstrap';

import { FileUploadField } from 'components/shared/FilesUploadField';
import { ImageUploadField } from 'components/shared/ImageUploadField';
import { SportType, EventType } from 'constants/enums';
import { ApplicationState } from 'store';
import CountriesSelect from 'components/shared/CountriesSelect';
import CategoriesComponents from './components/CategoriesComponent';
import BaseSelect, { SelectOption } from './components/selects/BaseSelect';
import api from 'config/api';
import {
    RhythmicGymnasticsEventModel,
    RhythmicGymnasticsCategoryModel,
} from 'config/api/models';
import { actionCreators as globalActions } from 'store/globalStore/actionCreators';
import { actionCreators as eventsActions } from 'store/eventsStore/actionCreators';
import ConfirmationModal from 'components/shared/ConfirmationModal';
import {
    RhythmicGymnasticsCategory,
    NewRhythmicGymnasticsEvent,
} from '../interfaces';

export default function NewEventPage() {
    const dispatch = useDispatch();
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [documents, setDocuments] = useState<File[]>([]);
    const [logo, setLogo] = useState<File | string | null>(null);
    const [categories, setCategories] = useState<RhythmicGymnasticsCategory[]>(
        localStorage.getItem('est-events-categories')
            ? JSON.parse(localStorage.getItem('est-events-categories')!)
            : []
    );
    const [showConfirmation, setShowConfirmation] = useState(false);
    const userCountry = useSelector(
        (state: ApplicationState) => state.global?.countryName
    );
    const { t } = useTranslation();
    const formRef = useRef<any>(null);

    const schema = Yup.object().shape({
        title: Yup.string().required(
            t('errors.required', {
                name: t('words.title'),
            })
        ),
        country: Yup.string().required(
            t('errors.required', {
                name: t('words.country'),
            })
        ),
        place: Yup.string().required(
            t('errors.required', {
                name: t('words.place'),
            })
        ),
        organizer: Yup.string().required(
            t('errors.required', {
                name: t('words.organizer'),
            })
        ),
        contacts: Yup.string().required(
            t('errors.required', {
                name: t('words.contacts'),
            })
        ),
    });

    const competitionTypeOptions = [
        {
            value: EventType.Live,
            text: t('words.live'),
        },
        {
            value: EventType.Virtual,
            text: t('words.virtual'),
        },
    ];

    function submitForm() {
        if (formRef.current) {
            // @ts-ignore
            formRef.current.handleSubmit();
        }
    }

    function saveCategory(category: RhythmicGymnasticsCategory) {
        const updatedCategories = [...categories, category];
        localStorage.setItem(
            'est-events-categories',
            JSON.stringify(updatedCategories)
        );
        setCategories(updatedCategories);
    }

    function updateCategory(
        category: RhythmicGymnasticsCategory,
        index: number
    ) {
        const updatedCategories = categories.map((x, i) =>
            i !== index ? x : category
        );
        localStorage.setItem(
            'est-events-categories',
            JSON.stringify(updatedCategories)
        );
        setCategories(updatedCategories);
    }

    function removeCategory(index: number) {
        const updatedCategories = categories.filter((x, i) => i !== index);
        localStorage.setItem(
            'est-events-categories',
            JSON.stringify(updatedCategories)
        );
        setCategories(updatedCategories);
    }

    function saveFormToLocalStorge(values: NewRhythmicGymnasticsEvent) {
        localStorage.setItem('est-events-new-event', JSON.stringify(values));
    }

    function getInitialValues() {
        const savedValues = localStorage.getItem('est-events-new-event');
        return savedValues ? JSON.parse(savedValues) : defaultFormValues;
    }

    function clearLocalStorageEvent() {
        localStorage.removeItem('est-events-categories');
        localStorage.removeItem('est-events-new-event');
        formRef.current.resetForm({
            values: { ...defaultFormValues },
        });
        setCategories([]);
    }

    const defaultFormValues: NewRhythmicGymnasticsEvent = {
        sport: SportType.RhythmicGymnastics,
        type: EventType.Live,
        title: '',
        startDate: new Date(Date.now()),
        endDate: new Date(Date.now()),
        country: userCountry ? userCountry : '',
        place: '',
        organizer: '',
        registrationDeadline: new Date(Date.now()),
        audioDeadline: new Date(Date.now()),
        contacts: '',
        notes: '',
    };
    return (
        <>
            <Row>
                <Col md={{ size: 6, offset: 3 }}>
                    <h4>{t('phrases.createEvent')}</h4>
                    <Formik
                        initialValues={getInitialValues()}
                        validationSchema={schema}
                        validateOnChange={validateOnChange}
                        initialErrors={{}}
                        innerRef={formRef}
                        onSubmit={async (values, { resetForm }) => {
                            const model: RhythmicGymnasticsEventModel = {
                                type: values.type,
                                title: values.title,
                                startDate: values.startDate,
                                endDate: values.endDate,
                                country: values.country,
                                place: values.place,
                                organizer: values.organizer,
                                registrationDeadline:
                                    values.registrationDeadline,
                                audioDeadline: values.audioDeadline,
                                contacts: values.contacts,
                                notes: values.notes,
                                logo: '',
                                categories: [],
                            };

                            categories.forEach((category) => {
                                const ct: RhythmicGymnasticsCategoryModel = {
                                    title: category.title,
                                    type: category.type,
                                    yearOfBirth: category.yearOfBirth,
                                    apparatuses: [],
                                };
                                category.firstApp.forEach((app) =>
                                    ct.apparatuses.push({
                                        name: 'FirstApp',
                                        apparatus: app.value,
                                        count: app.count,
                                    })
                                );
                                category.secondApp.forEach((app) =>
                                    ct.apparatuses.push({
                                        name: 'SecondApp',
                                        apparatus: app.value,
                                        count: app.count,
                                    })
                                );
                                category.thirdApp.forEach((app) =>
                                    ct.apparatuses.push({
                                        name: 'ThirdApp',
                                        apparatus: app.value,
                                        count: app.count,
                                    })
                                );
                                category.fourthApp.forEach((app) =>
                                    ct.apparatuses.push({
                                        name: 'FourthApp',
                                        apparatus: app.value,
                                        count: app.count,
                                    })
                                );
                                category.fifthApp.forEach((app) =>
                                    ct.apparatuses.push({
                                        name: 'FifthApp',
                                        apparatus: app.value,
                                        count: app.count,
                                    })
                                );
                                category.sixthApp.forEach((app) =>
                                    ct.apparatuses.push({
                                        name: 'SixthApp',
                                        apparatus: app.value,
                                        count: app.count,
                                    })
                                );
                                category.finalApp.forEach((app) =>
                                    ct.apparatuses.push({
                                        name: 'FinalApp',
                                        apparatus: app.value,
                                        count: app.count,
                                    })
                                );
                                model.categories.push(ct);
                            });
                            dispatch(globalActions.showLoader());
                            const newEvent = await api.events.createEvent(
                                model
                            );
                            let uploadActions = [];
                            if (logo) {
                                const logoFormData = new FormData();
                                logoFormData.append('File', logo!);
                                const uploadLogo = api.files
                                    .uploadEventLogo(newEvent.id, logoFormData)
                                    .then((response) => {
                                        newEvent.logo = response;
                                    })
                                    .catch((error) =>
                                        dispatch(
                                            globalActions.setMessage({
                                                status: error.status,
                                                title: error.title,
                                                message: error.message,
                                            })
                                        )
                                    );
                                uploadActions.push(uploadLogo);
                            }
                            if (documents.length > 0) {
                                const docsFormData = new FormData();
                                documents.forEach((doc) =>
                                    docsFormData.append('File', doc)
                                );
                                const uploadDocuments = api.files
                                    .uploadEventDocuments(
                                        newEvent.id,
                                        docsFormData
                                    )
                                    .catch((error) =>
                                        dispatch(
                                            globalActions.setMessage({
                                                status: error.status,
                                                title: error.title,
                                                message: error.message,
                                            })
                                        )
                                    );
                                uploadActions.push(uploadDocuments);
                            }

                            Promise.all(uploadActions)
                                .then(() => {
                                    dispatch(eventsActions.addEvent(newEvent));
                                    clearLocalStorageEvent();
                                    resetForm({
                                        values: { ...defaultFormValues },
                                    });
                                    setCategories([]);
                                    setDocuments([]);
                                    setLogo(null);
                                    dispatch(
                                        globalActions.setMessage({
                                            status: 200,
                                            title: '',
                                            message: t(
                                                'phrases.newEventCreated'
                                            ),
                                        })
                                    );
                                })
                                .finally(() =>
                                    dispatch(globalActions.hideLoader())
                                );
                        }}
                    >
                        {({ handleSubmit, values, errors, setFieldValue }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="type">{t('words.type')}*</Label>
                                    <BaseSelect
                                        title={t('words.type')}
                                        options={competitionTypeOptions}
                                        value={competitionTypeOptions.find(
                                            (x) => x.value === values.type
                                        )}
                                        onChange={(value: SelectOption) => {
                                            setFieldValue('type', value.value);
                                            saveFormToLocalStorge(values);
                                        }}
                                    />
                                    <FormFeedback>{errors.type}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="title">
                                        {t('words.title')}*
                                    </Label>
                                    <Input
                                        id="title"
                                        name="title"
                                        placeholder={t('words.title')}
                                        type="text"
                                        value={values.title}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'title',
                                                e.target.value
                                            );
                                            saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.title}
                                    />
                                    <FormFeedback>{errors.title}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="startDate">
                                        {t('words.startDate')}*
                                    </Label>
                                    <Input
                                        id="startDate"
                                        name="startDate"
                                        type="date"
                                        value={values.startDate}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'startDate',
                                                e.target.value
                                            );
                                            saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.startDate}
                                    />
                                    <FormFeedback>
                                        {errors.startDate}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="endDate">
                                        {t('words.endDate')}*
                                    </Label>
                                    <Input
                                        id="endDate"
                                        name="endDate"
                                        type="date"
                                        value={values.endDate}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'endDate',
                                                e.target.value
                                            );
                                            saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.endDate}
                                    />
                                    <FormFeedback>
                                        {errors.endDate}
                                    </FormFeedback>
                                </FormGroup>
                                <CountriesSelect
                                    value={values.country}
                                    onChange={(e: string) =>
                                        setFieldValue('country', e)
                                    }
                                    error={!!errors.country}
                                />
                                <FormGroup>
                                    <Label for="place">
                                        {t('words.place')}*
                                    </Label>
                                    <Input
                                        id="place"
                                        name="place"
                                        placeholder={t('words.place')}
                                        type="text"
                                        value={values.place}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'place',
                                                e.target.value
                                            );
                                            saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.place}
                                    />
                                    <FormFeedback>{errors.place}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="organizer">
                                        {t('words.organizer')}*
                                    </Label>
                                    <Input
                                        id="organizer"
                                        name="organizer"
                                        placeholder={t('words.organizer')}
                                        type="text"
                                        value={values.organizer}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'organizer',
                                                e.target.value
                                            );
                                            saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.organizer}
                                    />
                                    <FormFeedback>
                                        {errors.organizer}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="registrationDeadline">
                                        {t('words.registrationDeadline')}*
                                    </Label>
                                    <Input
                                        id="registrationDeadline"
                                        name="registrationDeadline"
                                        placeholder={t(
                                            'words.registrationDeadline'
                                        )}
                                        type="date"
                                        value={values.registrationDeadline}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'registrationDeadline',
                                                e.target.value
                                            );
                                            saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.registrationDeadline}
                                    />
                                    <FormFeedback>
                                        {errors.registrationDeadline}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="audioDeadline">
                                        {t('words.audioDeadline')}*
                                    </Label>
                                    <Input
                                        id="audioDeadline"
                                        name="audioDeadline"
                                        placeholder={t('words.audioDeadline')}
                                        type="date"
                                        value={values.audioDeadline}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'audioDeadline',
                                                e.target.value
                                            );
                                            saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.audioDeadline}
                                    />
                                    <FormFeedback>
                                        {errors.registrationDeadline}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="contacts">
                                        {t('words.contacts')}*
                                    </Label>
                                    <Input
                                        id="contacts"
                                        name="contacts"
                                        placeholder={t('words.contacts')}
                                        type="textarea"
                                        value={values.contacts}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'contacts',
                                                e.target.value
                                            );
                                            saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.contacts}
                                    />
                                    <FormFeedback>
                                        {errors.contacts}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="notes">
                                        {t('words.notes')}
                                    </Label>
                                    <Input
                                        id="notes"
                                        name="notes"
                                        placeholder={t('words.notes')}
                                        type="textarea"
                                        value={values.notes}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'notes',
                                                e.target.value
                                            );
                                            saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.notes}
                                    />
                                    <FormFeedback>{errors.notes}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{t('words.documents')}</Label>
                                    <FileUploadField
                                        title={t('words.addFile')}
                                        onChange={setDocuments}
                                        files={documents}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{t('words.addLogo')}</Label>
                                    <ImageUploadField
                                        title={t('words.logo')}
                                        onChange={setLogo}
                                        file={logo}
                                    />
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                    <CategoriesComponents
                        categories={categories}
                        onSave={saveCategory}
                        onDelete={removeCategory}
                        onEdit={updateCategory}
                    />
                    <Button
                        block
                        outline
                        style={{ marginBottom: 12 }}
                        color="secondary"
                        onClick={() => setShowConfirmation(true)}
                    >
                        {t('words.clear').toUpperCase()}
                    </Button>
                    <Button
                        block
                        color="primary"
                        onClick={() => {
                            setValidateOnChange(true);
                            submitForm();
                        }}
                    >
                        {t('phrases.saveEvent')}
                    </Button>
                </Col>
            </Row>
            <ConfirmationModal
                isOpen={showConfirmation}
                text={t('phrases.doYouWantClearEvent')}
                actionText={t('words.delete')}
                action={clearLocalStorageEvent}
                onClose={() => setShowConfirmation(false)}
            />
        </>
    );
}
