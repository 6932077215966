import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    InputGroup,
    Button,
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

import { UserEmailLoginModel } from 'config/api/models';
import accountStore from 'store/accountStore';

export default function EmailLoginForm() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isPassword, setIsPassword] = React.useState(true);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const returnUrl = queryParams.get('returnUrl');

    const schema = Yup.object().shape({
        email: Yup.string()
            .email(t('errors.email'))
            .required(
                t('errors.required', {
                    name: t('words.email'),
                })
            ),
        password: Yup.string().required(
            t('errors.required', {
                name: t('words.password'),
            })
        ),
    });

    function logIn(model: UserEmailLoginModel) {
        dispatch(accountStore.actionCreators.logIn(model, returnUrl));
    }
    return (
        <Formik
            initialValues={
                {
                    email: '',
                    password: '',
                    rememberMe: false,
                } as UserEmailLoginModel
            }
            validationSchema={schema}
            validateOnChange={validateOnChange}
            initialErrors={{}}
            onSubmit={(values) => {
                logIn(values);
            }}
        >
            {({ handleSubmit, values, errors, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="email">{t('words.email')}</Label>
                        <Input
                            id="email"
                            name="email"
                            placeholder={t('words.email')}
                            type="text"
                            value={values.email}
                            onChange={(e) =>
                                setFieldValue('email', e.target.value)
                            }
                            invalid={!!errors.email}
                        />
                        <FormFeedback>{errors.email}</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label for="password">{t('words.password')}</Label>
                        <InputGroup>
                            <Input
                                name="password"
                                placeholder={t('words.password')}
                                type={isPassword ? 'password' : 'text'}
                                value={values.password}
                                style={{ backgroundImage: 'none' }}
                                onChange={(e) =>
                                    setFieldValue('password', e.target.value)
                                }
                                invalid={!!errors.password}
                            />
                            <FormFeedback>{errors.password}</FormFeedback>
                            <div
                                className="input-group-text"
                                onClick={() => setIsPassword(!isPassword)}
                            >
                                {isPassword ? <Eye /> : <EyeSlash />}
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <div className="d-flex justify-content-between align-items-center">
                        <FormGroup check inline>
                            <Input
                                type="checkbox"
                                checked={values.rememberMe}
                                onChange={() =>
                                    setFieldValue(
                                        'rememberMe',
                                        !values.rememberMe
                                    )
                                }
                            />
                            <Label check>{t('words.rememberMe')}</Label>
                        </FormGroup>
                        <a href="auth/password-recovery">
                            {t('phrases.forgotPassword')}
                        </a>
                    </div>
                    <Button
                        block
                        color="primary"
                        type="submit"
                        onClick={() => setValidateOnChange(true)}
                    >
                        {t('words.login')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
