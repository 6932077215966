import React from 'react';
import { useHistory } from 'react-router';

import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { CheckCircle } from 'react-bootstrap-icons';

import FullScreenMessageContainer from 'components/shared/FullScreenMessageContainer';

export default function SuccessRecoveryMessage() {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <FullScreenMessageContainer>
            <CheckCircle className="title-img" />
            <h3>{t('phrases.passwordHasBeenChanged')}</h3>
            <p className="body-regular">{t('phrases.pleaseLogIn')}</p>
            <Button
                block
                color="primary"
                type="submit"
                onClick={() => history.push('/auth/login')}
            >
                {t('words.login')}
            </Button>
            <div className="bottom-massages-group"></div>
        </FullScreenMessageContainer>
    );
}
