import React from 'react';
import { useHistory } from 'react-router-dom';

import countryList from 'react-select-country-list';
import Flag from 'react-world-flags';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import EventTypeLabel from './EventTypeLabel';
import { formatDate } from 'helpers/dateTimeHelper';
import EventInfoblock from '../components/EventInfoblock';
import EventLogo from 'components/shared/EventLogo';
import { RhythmicGymnasticsEvent } from '../interfaces';

interface EventComponentProps extends RhythmicGymnasticsEvent {
    isDraft: boolean;
}

export default function EventComponent(props: EventComponentProps) {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <div className="event-list-container">
            <div className="d-flex justify-content-between">
                <div className="d-flex flex-column justify-content-between">
                    <div className="d-flex align-items-start">
                        <Flag
                            code={countryList().getValue(props.country)}
                            height={34}
                            alt={props.country}
                        />
                        <EventTypeLabel type={props.type} />
                    </div>
                    <div className="event-start-end-dates">
                        {formatDate(props.startDate)} -{' '}
                        {formatDate(props.endDate)}
                    </div>
                </div>
                <EventLogo src={props.logo} isDetails={false} />
            </div>
            <h5 className="event-title">{props.title}</h5>
            <div className="d-flex justify-content-between">
                <EventInfoblock
                    title={`${t('words.place')}:`}
                    text={props.place}
                    rightPadding={true}
                />
                <EventInfoblock
                    title={`${t('words.registrationDeadline')}:`}
                    text={`${formatDate(props.registrationDeadline)}`}
                    rightAlign={true}
                />
            </div>
            <div className="d-flex justify-content-between">
                <EventInfoblock
                    title={`${t('words.organizer')}:`}
                    text={props.organizer}
                />
            </div>
            <div className="event-list-categories">
                <p>{props.categoriesTitle}</p>
                <Button
                    block
                    outline
                    color="primary"
                    type="button"
                    onClick={() =>
                        history.push(
                            props.isDraft
                                ? `/my-events/${props.id}`
                                : `/events/${props.id}`
                        )
                    }
                >
                    {t('words.details')}
                </Button>
            </div>
        </div>
    );
}
