import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import {
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Button,
    Table,
} from 'reactstrap';
import { Check2Square, Trash3, Pencil } from 'react-bootstrap-icons';

import {
    RhythmicGymnasticsEventGroupMember,
    RhythmicGymnasticsEventParticipiant,
} from '../../interfaces';
import { ImageUploadField } from 'components/shared/ImageUploadField';
import images from 'assets/images';
import api from 'config/api';
import { prepareProfilePhotoUrl } from 'helpers/functions';

interface GroupMembersComponentProps {
    onAdd: (member: RhythmicGymnasticsEventGroupMember) => void;
    onEdit: (member: RhythmicGymnasticsEventGroupMember, index: number) => void;
    onDelete: (index: number) => void;
    members: RhythmicGymnasticsEventGroupMember[];
}

export default function GroupMembersComponent(
    props: GroupMembersComponentProps
) {
    const { t } = useTranslation();
    const formikRef =
        useRef<FormikProps<RhythmicGymnasticsEventGroupMember>>(null);
    const [picture, setPicture] = useState<File | string | null>(null);
    const [showForm, setShowForm] = useState(false);
    const [suggest, setSuggest] = useState<
        RhythmicGymnasticsEventParticipiant[]
    >([]);
    const memberToEditIndex = useRef<number | null>(null);
    const initialFormValues: RhythmicGymnasticsEventGroupMember = {
        firstName: '',
        lastName: '',
        yearOfBirth: 0,
        licenseNumber: '',
        photo: '',
        id: '',
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required(
            t('errors.required', {
                name: t('words.firstName'),
            })
        ),
        lastName: Yup.string().required(
            t('errors.required', {
                name: t('words.lastName'),
            })
        ),
        yearOfBirth: Yup.number()
            .min(1950)
            .required(
                t('errors.required', {
                    name: t('words.dateOfBirth'),
                })
            ),
    });

    function addMember() {
        formikRef.current?.submitForm();
    }

    function preparePhotoPreview(photo: File | string | null) {
        if (photo) {
            const source =
                typeof photo === 'string' ? photo : URL.createObjectURL(photo);
            return (
                <img
                    src={source}
                    alt="profile"
                    height={36}
                    width={36}
                    style={{ objectFit: 'cover' }}
                />
            );
        }
    }

    return (
        <>
            <h4 style={{ textAlign: 'start' }}>{t('words.groupMembers')}</h4>
            {props.members.length > 0 && (
                <Table striped borderless>
                    <tbody>
                        {props.members.map((member, i) => (
                            <tr key={member.lastName + i}>
                                <td>{`${member.lastName} ${member.firstName}`}</td>
                                <td>{member.yearOfBirth}</td>
                                <td style={{ textAlign: 'end' }}>
                                    {preparePhotoPreview(member.photo)}
                                </td>
                                <td align="right">
                                    <Pencil
                                        cursor="pointer"
                                        color="#086A98"
                                        onClick={() => {
                                            if (showForm) {
                                                setShowForm(false);
                                            }
                                            memberToEditIndex.current = i;
                                            setPicture(props.members[i].photo);
                                            setShowForm(true);
                                        }}
                                    />
                                </td>
                                <td>
                                    <Trash3
                                        cursor="pointer"
                                        color="#DC3545"
                                        onClick={() => props.onDelete(i)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            {showForm ? (
                <div className="group-member-from-container">
                    <div
                        className="d-flex justify-content-between alignt-center"
                        style={{ marginBottom: 20 }}
                    >
                        <span className="text-primary font-weight-bold">
                            {t('words.addNewMember')}
                        </span>
                        <img
                            src={images.icons.noApp}
                            alt="close-modal"
                            onClick={() => {
                                setPicture(null);
                                memberToEditIndex.current = null;
                                setShowForm(false);
                            }}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>

                    <Formik
                        innerRef={formikRef}
                        initialValues={
                            memberToEditIndex.current !== null
                                ? props.members[memberToEditIndex.current]
                                : initialFormValues
                        }
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        onSubmit={(values) => {
                            const memb = { ...values, photo: picture };
                            if (memberToEditIndex.current !== null) {
                                props.onEdit(memb, memberToEditIndex.current);
                                memberToEditIndex.current = null;
                            } else {
                                props.onAdd(memb);
                            }

                            setShowForm(false);
                            setPicture(null);
                        }}
                    >
                        {({ values, errors, setFieldValue }) => (
                            <Form>
                                <FormGroup style={{ position: 'relative' }}>
                                    <Label for="lastName">
                                        {t('words.lastName')} *
                                    </Label>
                                    <Input
                                        id="lastName"
                                        name="lastName"
                                        placeholder={t('words.lastName')}
                                        type="text"
                                        value={values.lastName}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'lastName',
                                                e.target.value
                                            );
                                            if (values.lastName.length > 2) {
                                                api.events
                                                    .getMyGymnasts(
                                                        values.lastName
                                                    )
                                                    .then((res) =>
                                                        setSuggest(res)
                                                    );
                                            }
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.lastName}
                                    />
                                    <FormFeedback>
                                        {errors.lastName}
                                    </FormFeedback>
                                    {suggest.length > 0 && (
                                        <div
                                            className="form-select form-select-options-container"
                                            style={{ top: 'calc(100% + 10px)' }}
                                        >
                                            {suggest.map((s) => (
                                                <div
                                                    key={s.id}
                                                    className="app-option"
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'firstName',
                                                            s.firstName
                                                        );
                                                        setFieldValue(
                                                            'lastName',
                                                            s.lastName
                                                        );
                                                        setFieldValue(
                                                            'licenseNumber',
                                                            s.licenseNumber
                                                        );
                                                        setFieldValue(
                                                            'yearOfBirth',
                                                            new Date(
                                                                s.birthDate
                                                            ).getFullYear() !==
                                                                1
                                                                ? new Date(
                                                                      s.birthDate
                                                                  ).getFullYear()
                                                                : ''
                                                        );
                                                        setFieldValue(
                                                            'id',
                                                            s.id
                                                        );
                                                        setPicture(
                                                            prepareProfilePhotoUrl(
                                                                s.photo
                                                            )
                                                        );
                                                        setSuggest([]);
                                                    }}
                                                >
                                                    {`${s.lastName} ${s.firstName}`}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="firstName">
                                        {t('words.firstName')} *
                                    </Label>
                                    <Input
                                        id="firstName"
                                        name="firstName"
                                        placeholder={t('words.firstName')}
                                        type="text"
                                        value={values.firstName}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'firstName',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.firstName}
                                    />
                                    <FormFeedback>
                                        {errors.firstName}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="yearOfBirth">
                                        {t('words.yearOfBirth')} *
                                    </Label>
                                    <Input
                                        id="yearOfBirth"
                                        name="yearOfBirth"
                                        placeholder={t('words.yearOfBirth')}
                                        type="text"
                                        pattern="[0-9]+"
                                        value={
                                            values.yearOfBirth
                                                ? values.yearOfBirth
                                                : ''
                                        }
                                        onChange={(e) => {
                                            setFieldValue(
                                                'yearOfBirth',
                                                +e.target.value
                                            );
                                        }}
                                        invalid={!!errors.yearOfBirth}
                                    />
                                    <FormFeedback>
                                        {errors.yearOfBirth}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="licenseNumber">
                                        {t('words.licenseNumber')}
                                    </Label>
                                    <Input
                                        id="licenseNumber"
                                        name="licenseNumber"
                                        placeholder={t('words.licenseNumber')}
                                        type="text"
                                        value={values.licenseNumber}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'licenseNumber',
                                                e.target.value
                                            );
                                            //saveFormToLocalStorge(values);
                                        }}
                                        invalid={!!errors.licenseNumber}
                                    />
                                    <FormFeedback>
                                        {errors.licenseNumber}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        {t('phrases.uploadYourPicture')}
                                    </Label>
                                    <ImageUploadField
                                        title={t('words.upload')}
                                        onChange={setPicture}
                                        file={picture}
                                    />
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                    <Button
                        outline
                        color="primary"
                        type="button"
                        block
                        style={{ marginTop: 24 }}
                        onClick={() => {
                            //setValidateOnChange(true);
                            addMember();
                        }}
                    >
                        <Check2Square />
                        {t('words.save')}
                    </Button>
                </div>
            ) : (
                <Button
                    outline
                    color="primary"
                    type="button"
                    block
                    style={{ marginBottom: 64 }}
                    onClick={() => setShowForm(true)}
                    disabled={props.members.length === 5}
                >
                    <Check2Square />
                    {t('words.addEntry').toUpperCase()}
                </Button>
            )}
        </>
    );
}
