export function formatDate(
    date: Date | string | undefined,
    separator: string = '.'
) {
    if (!date) {
        return;
    }
    const dt = new Date(date);
    const day = dt.getDate();
    const month = dt.getMonth() + 1;
    const year = dt.getFullYear();
    return `${day < 10 ? '0' + day : day}${separator}${
        month < 10 ? '0' + month : month
    }${separator}${year}`;
}
