import React, { FC } from 'react';

const FullScreenMessageContainer: FC<{}> = ({ children }) => {
    return (
        <div
            className="d-flex flex-column justify-content-start align-items-center full-screen-message-container"
            style={{ height: 'calc(100vh - 78px)' }}
        >
            {children}
        </div>
    );
};

export default FullScreenMessageContainer;
