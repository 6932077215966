import React, { useEffect, useState } from 'react';

import Input from 'react-phone-number-input/input';
import countryNames from 'react-phone-number-input/locale/en.json';
import { FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { InputProps } from 'constants/interfaces';
import { getKeyByValue } from 'helpers/functions';
import { CountryCode } from 'libphonenumber-js/types';

interface PhoneInputProps extends InputProps {
    country: string;
    isRequired: boolean;
}

export default function PhoneInputField(props: PhoneInputProps) {
    const { t } = useTranslation();
    const [code, setCode] = useState<CountryCode | undefined>(undefined);

    useEffect(() => {
        props.onChange('');
        setCode(getKeyByValue(countryNames, props.country) as CountryCode);
        // eslint-disable-next-line
    }, [props.country]);

    return (
        <FormGroup>
            <Label for="phoneNumber">{t('words.phoneNumber')}</Label>
            <Input
                className="form-control"
                country={code}
                //limitMaxLength
                disabled={!!!getKeyByValue(countryNames, props.country)}
                placeholder={
                    props.isRequired
                        ? t('words.phoneNumber')
                        : t('words.phoneNumberOptional')
                }
                international
                withCountryCallingCode
                // @ts-ignore
                value={props.value}
                // @ts-ignore
                onChange={props.onChange}
            />
        </FormGroup>
    );
}
