import * as React from 'react';
import { Route, Switch } from 'react-router';

import RegistrationPage from 'components/account/registration/RegistrationPage';
import EmailConfirmationPage from 'components/account/registration/EmailConfirmationPage';
import LogInPage from 'components/account/login/LogInPage';
import PasswordRecoveryPage from 'components/account/passwordRecovery/PasswordRecoveryPage';
import NotFoundPage from './NotFoundPage';
import Home from 'components/Home';
import EventsPage from 'components/events/EventsPage';
import EventDetailsPage from 'components/events/eventDetails/EventDetailsPage';
import SingleRegistrationPage from 'components/events/registration/SingleRegistrationPage';
import GroupRegistrationPage from 'components/events/registration/GroupRegistrationPage';
import PrivacyPolicy from 'components/policies/PrivacyPolicy';

export default function GuestRouter() {
    return (
        <>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/events" component={EventsPage} />
                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route
                    path="/registration/emailconfirmation"
                    component={EmailConfirmationPage}
                />
                <Route
                    path="/registration"
                    exact
                    component={RegistrationPage}
                />
                <Route path="/auth/login" component={LogInPage} />
                <Route
                    path="/auth/password-recovery"
                    component={PasswordRecoveryPage}
                />
                <Route
                    path="/events/:id/registration"
                    component={SingleRegistrationPage}
                />
                <Route
                    path="/events/:id/group-registration"
                    component={GroupRegistrationPage}
                />
                <Route path="/events/:id" component={EventDetailsPage} />
                <Route path="*" component={NotFoundPage} />
            </Switch>
        </>
    );
}
