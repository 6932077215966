import React from 'react';

import { SortingOption } from 'constants/enums';
import { prepareSortingIcon } from 'helpers/functions';

interface SortOptionProps {
    title: string;
    onClick: () => void;
    sortBy: SortingOption;
}

export default function SortOption(props: SortOptionProps) {
    return (
        <div className="sort-option" onClick={props.onClick}>
            {props.title}
            <img
                src={prepareSortingIcon(props.sortBy)}
                alt="sort-arrow"
                style={{ marginLeft: 12 }}
                height={24}
            />
        </div>
    );
}
