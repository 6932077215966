import accountStore from './accountStore';
import globalStore from './globalStore';
import eventsStore from './eventsStore';
import { AccountState } from './accountStore/reducer';
import { GlobalState } from './globalStore/reducer';
import { EventsState } from './eventsStore/reducer';

// The top-level state object
export interface ApplicationState {
    global: GlobalState | undefined;
    account: AccountState | undefined;
    events: EventsState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    global: globalStore.reducer,
    account: accountStore.reducer,
    events: eventsStore.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (
        dispatch: (action: TAction) => void,
        getState: () => ApplicationState
    ): void;
}
