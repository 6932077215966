import { GlobalActions } from './actions';
import { AppThunkAction } from '..';
import { ResponseError } from 'constants/interfaces';
import { defaultMessage } from './reducer';
import api from 'config/api';

export const actionCreators = {
    showLoader: (): AppThunkAction<GlobalActions> => (dispatch) => {
        dispatch({ type: 'GLOBAL_SHOW_LOADER' });
    },
    hideLoader: (): AppThunkAction<GlobalActions> => (dispatch) => {
        dispatch({ type: 'GLOBAL_HIDE_LOADER' });
    },
    setMessage:
        (message: ResponseError): AppThunkAction<GlobalActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: message });
        },
    clearMessage: (): AppThunkAction<GlobalActions> => (dispatch) => {
        dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: defaultMessage });
    },
    getLocationCountry: (): AppThunkAction<GlobalActions> => (dispatch) => {
        dispatch({ type: 'GLOBAL_GET_COUNTRY_START' });
        api.global
            .getLocationCountry()
            .then((response) => {
                dispatch({
                    type: 'GLOBAL_GET_COUNTRY_SUCCESS',
                    payload: response,
                });
            })
            .catch((error) => console.log(error));
    },
};
