import React from 'react';

interface EventDetailsInfoblockProps {
    title: string;
    text: string;
    rightAlign?: boolean;
    oneLine?: boolean;
    boldText?: boolean;
    increaseTextSize?: boolean;
}

export default function EventDetailsInfoblock(
    props: EventDetailsInfoblockProps
) {
    const textAlign = props.rightAlign ? 'end' : 'start';
    const fontWeight = props.boldText ? 700 : 400;
    const leftPadding = props.oneLine ? 12 : 0;
    const fontSize = props.increaseTextSize ? 16 : 16;
    return (
        <div
            className={`event-details-infoblock-container ${
                props.oneLine ? 'd-flex align-items-center' : ''
            }`}
        >
            {props.oneLine ? (
                <h6 style={{ textAlign: textAlign, marginBottom: 0 }}>
                    {props.title}
                </h6>
            ) : (
                <h6 style={{ textAlign: textAlign }}>{props.title}</h6>
            )}

            <p
                style={{
                    textAlign: textAlign,
                    fontWeight: fontWeight,
                    paddingLeft: leftPadding,
                    fontSize: fontSize,
                }}
            >
                {props.text}
            </p>
        </div>
    );
}
