import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import accountStore from 'store/accountStore';
import LanguageSwitcher from './LanguageSwitcher';

export default function UserNavMenu(props: { handleClose?: () => void }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    function logOut(e: React.SyntheticEvent) {
        e.preventDefault();
        dispatch(accountStore.actionCreators.logOut());
        close();
        history.push('/');
    }

    function close() {
        props.handleClose && props.handleClose();
    }

    return (
        <>
            <NavItem key="new-event" onClick={() => close()}>
                <NavLink
                    tag={Link}
                    className="text-dark"
                    to="/events/create"
                    onClick={() => close()}
                >
                    {t('words.createEvent')}
                </NavLink>
            </NavItem>
            <NavItem key="my-events" onClick={() => close()}>
                <NavLink
                    tag={Link}
                    className="text-dark"
                    to="/my-events"
                    onClick={() => close()}
                >
                    {t('phrases.myEvents')}
                </NavLink>
            </NavItem>
            <LanguageSwitcher />
            <NavItem key="logout">
                <NavLink
                    tag={Link}
                    className="text-white btn btn-primary btn-lg btn-block"
                    to="/"
                    onClick={(e) => logOut(e)}
                >
                    {t('words.logout')}
                </NavLink>
            </NavItem>
        </>
    );
}
