import React, { useState } from 'react';

import Flag from 'react-world-flags';
import countryList from 'react-select-country-list';
import { Table } from 'reactstrap';

import { RhythmicGymnasticsEventTeam } from '../interfaces';
import images from 'assets/images';
import { prepareProfilePhotoUrl } from 'helpers/functions';
import { RhythmicGymnasticsEventParticipiantDetails } from '../interfaces';

interface EventTeamComponentProps {
    team: RhythmicGymnasticsEventTeam;
    index: number;
}

export default function EventTeamComponent(props: EventTeamComponentProps) {
    const [showMembers, setShowMembers] = useState(false);
    return (
        <>
            <tr
                style={
                    showMembers
                        ? { backgroundColor: '#C3DEEB', cursor: 'pointer' }
                        : { cursor: 'pointer' }
                }
                key={props.team.name}
                onClick={() => setShowMembers(!showMembers)}
            >
                <td style={showMembers ? { boxShadow: 'none' } : {}}>
                    {props.index + 1}
                </td>
                <td
                    align="right"
                    style={showMembers ? { boxShadow: 'none' } : {}}
                >
                    <img src={images.icons.teamIcon} alt="tean" />
                </td>
                <td style={showMembers ? { boxShadow: 'none' } : {}}>
                    {props.team.name}
                </td>
                <td style={showMembers ? { boxShadow: 'none' } : {}}>
                    {props.team.club}
                </td>
                <td style={showMembers ? { boxShadow: 'none' } : {}}>
                    <Flag
                        code={countryList().getValue(props.team.country)}
                        height={16}
                        width={24}
                    />{' '}
                    {countryList().getValue(props.team.country)}
                </td>
            </tr>
            {showMembers && (
                <tr>
                    <td colSpan={4} style={{ padding: 0 }}>
                        <Table striped borderless style={{ marginBottom: 0 }}>
                            <tbody>
                                {props.team.members.map(
                                    (
                                        part: RhythmicGymnasticsEventParticipiantDetails
                                    ) => (
                                        <tr
                                            key={
                                                part.firstName +
                                                '-' +
                                                part.lastName
                                            }
                                        >
                                            <td></td>
                                            <td>{`${part.lastName} ${part.firstName}`}</td>
                                            <td>
                                                {new Date(
                                                    part.birthDate
                                                ).getFullYear() !== 1
                                                    ? new Date(
                                                          part.birthDate
                                                      ).getFullYear()
                                                    : ''}
                                            </td>
                                            <td>
                                                {part.photo ? (
                                                    <img
                                                        className="participiant-table-photo"
                                                        alt="profile"
                                                        src={prepareProfilePhotoUrl(
                                                            part.photo
                                                        )}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            height: 36,
                                                            width: 36,
                                                        }}
                                                    ></div>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    </td>
                </tr>
            )}
        </>
    );
}
