import React from 'react';

import images from 'assets/images';

interface EventLogoProps {
    src: string;
    isDetails: boolean;
}

export default function EventLogo(props: EventLogoProps) {
    return props.src ? (
        <img
            alt="event-logo"
            src={props.src}
            height={props.isDetails ? 136 : 68}
        />
    ) : (
        <div
            className={props.isDetails ? 'event-logo-details' : 'event-logo'}
            style={{
                backgroundImage: `url(${
                    props.isDetails ? images.mainLogoLg : images.mainLogoSm
                })`,
            }}
        ></div>
    );
}
