export enum SocialNetwork {
    Facebook,
}

export enum UserRole {
    USER = 'User',
    ADMIN = 'Admin',
}

export enum EventType {
    Live,
    Virtual,
    /* Postponed,
    Canceled, */
}

export enum SportType {
    RhythmicGymnastics,
}

export enum BaseCategoryType {
    Individual,
    Group,
}

export enum RhythmicGymnasticsAppartType {
    None = 'None',
    ByChoice = 'ByChoice',
    WithoutApparatus = 'WithoutApparatus',
    Rope = 'Rope',
    Hoop = 'Hoop',
    Ball = 'Ball',
    Clubs = 'Clubs',
    Ribbon = 'Ribbon',
}

export enum RhytmicGymnasticsEventStatus {
    Draft,
    WaitingForApproval,
    Active,
    Inactive,
}

export enum RhytmicGymnasticsEventParticipiant {
    Gymnast,
    Coach,
    Arbiter,
    Manager,
    MedicalStaff,
    Spectator,
    Other,
}

export enum SortingOption {
    None,
    Ascending,
    Descending,
}
