import React from 'react';
import { useDispatch } from 'react-redux';

import FacebookLogin from 'react-facebook-login';
import { useTranslation } from 'react-i18next';

import { SocialNetwork } from 'constants/enums';
import { environmentVariables } from 'constants/variables';
import { actionCreators } from 'store/accountStore/actionCreators';
import images from 'assets/images';

export interface PorposalFooterProps {
    text: string;
    link: string;
    goTo: 'login' | 'registration';
}

export default function ProposalFooter(props: PorposalFooterProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const returnUrl = queryParams.get('returnUrl');
    const responseFacebook = (response: any) => {
        dispatch(
            actionCreators.logInSSO(
                {
                    token: response.accessToken,
                    socialNetwork: SocialNetwork.Facebook,
                },
                returnUrl
            )
        );
    };

    return (
        <>
            <p className="body-regular text-center">
                {props.text}{' '}
                <a
                    href={
                        props.goTo === 'login' ? '/auth/login' : '/registration'
                    }
                >
                    {props.link}
                </a>
            </p>
            <div className="d-flex justify-content-between align-items-center">
                <div className="dashed-horizontal"></div>
                <span style={{ margin: '0 20px' }}>
                    {t('phrases.orLoginwith')}
                </span>
                <div className="dashed-horizontal"></div>
            </div>
            <FacebookLogin
                appId={environmentVariables.facebookAppId!}
                isDisabled //temporary
                autoLoad={false}
                fields="name,email,picture"
                //onClick={componentClicked}
                callback={responseFacebook}
                cssClass="facebook-sso-btn"
                textButton=""
                redirectUri={undefined}
                icon={
                    <img src={images.icons.facebookCircleBtn} alt="facebook" />
                }
            />
        </>
    );
}
