import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    ModalFooter,
} from 'reactstrap';
import { t } from 'i18next';
import Flag from 'react-world-flags';
import countryList from 'react-select-country-list';
import { Funnel, Pencil } from 'react-bootstrap-icons';
import { Formik } from 'formik';
import _ from 'lodash';

import { ApplicationState } from 'store';
import {
    RhythmicGymnasticsCategoryDetails,
    RhythmicGymnasticsAppart,
    ParticipiantsFilter,
    RhythmicGymnasticsEventParticipiantDetails,
} from '../interfaces';
import {
    prepareAppIcon,
    prepareProfilePhotoUrl,
    changeSortingOption,
} from 'helpers/functions';
import {
    RhythmicGymnasticsAppartType,
    RhytmicGymnasticsEventParticipiant,
    SortingOption,
    BaseCategoryType,
    UserRole,
} from 'constants/enums';
import {
    rhytmicGymnasticsEventParticipiantMultipleOptions,
    rhytmicGymnasticsEventParticipiantOptions,
} from 'constants/variables';
import SortOption from 'components/shared/SortOption';

import CountriesSelect from 'components/shared/CountriesSelect';
import images from 'assets/images';
import BaseSelect, {
    SelectOption,
} from '../newEvent/components/selects/BaseSelect';
import EventTeamComponent from '../components/EventTeamComponent';
import { useHistory, useLocation } from 'react-router';

export default function EventParticipiants() {
    const location = useLocation();
    const history = useHistory();
    const event = useSelector(
        (state: ApplicationState) => state.events?.eventDetails
    );
    const user = useSelector((state: ApplicationState) => state.account);
    const [sortByName, setSortByName] = useState<SortingOption>(
        SortingOption.None
    );
    const [sortByDate, setSortByDate] = useState<SortingOption>(
        SortingOption.None
    );
    const [showFilters, setShowFilters] = useState(false);
    const formRef = useRef(null);
    const [selectOption, setSelectOptions] = useState<SelectOption[]>([]);

    const initailFilterValues: ParticipiantsFilter = {
        lastName: '',
        country: '',
        yearOfBirth: undefined,
        category: '',
        type: null,
    };

    const [filter, setFilter] = useState<ParticipiantsFilter>({
        ...initailFilterValues,
    });

    function submitForm() {
        if (formRef.current) {
            setShowFilters(false);
            // @ts-ignore
            formRef.current.handleSubmit();
        }
    }

    function cleanForm() {
        if (formRef.current) {
            // @ts-ignore
            formRef.current.resetForm(initailFilterValues);
            setFilter({ ...initailFilterValues });
            setShowFilters(false);
        }
    }

    const closeBtn = (
        <img
            src={images.icons.noApp}
            alt="close-modal"
            onClick={() => setShowFilters(false)}
            style={{ cursor: 'pointer' }}
        />
    );

    function filterParticipiants(
        participiants: RhythmicGymnasticsEventParticipiantDetails[]
    ) {
        if (filter.category) {
            participiants = participiants.filter(
                (x) =>
                    x.categoryId ===
                    event?.categories.find((y) => y.title === filter.category)
                        ?.id
            );
        }
        if (filter.country) {
            participiants = participiants.filter(
                (x) => x.country === filter.country
            );
        }
        if (filter.lastName) {
            participiants = participiants.filter((x) =>
                x.lastName.includes(filter.lastName)
            );
        }
        if (filter.type) {
            participiants = participiants.filter((x) => x.type === filter.type);
        }
        if (filter.yearOfBirth) {
            participiants = participiants.filter(
                (x) =>
                    new Date(x.birthDate).getFullYear() === filter.yearOfBirth
            );
        }

        return participiants;
    }

    function prepareCategoryAppIcons(
        apps: RhythmicGymnasticsAppart[],
        isGroup?: boolean
    ) {
        return apps.map(
            (app, i) =>
                app.value !== RhythmicGymnasticsAppartType.None && (
                    <div
                        key={`${app.value}-${i}`}
                        className="d-flex align-items-center"
                    >
                        {(app.count > 1 || isGroup) && (
                            <span style={{ marginLeft: 5 }}>{app.count}</span>
                        )}
                        <img
                            className="svg-black-filter"
                            style={{ marginLeft: 5 }}
                            height={24}
                            src={prepareAppIcon(app.value)}
                            alt={app.value}
                        />
                    </div>
                )
        );
    }

    function prepareCategoryApps(category: RhythmicGymnasticsCategoryDetails) {
        let icons = [];
        if (category.firstApp.length > 0) {
            icons.push(
                prepareCategoryAppIcons(
                    category.firstApp,
                    category.type === BaseCategoryType.Group
                )
            );
        }
        if (category.secondApp.length > 0) {
            icons.push(
                prepareCategoryAppIcons(
                    category.secondApp,
                    category.type === BaseCategoryType.Group
                )
            );
        }
        if (category.thirdApp.length > 0) {
            icons.push(
                prepareCategoryAppIcons(
                    category.thirdApp,
                    category.type === BaseCategoryType.Group
                )
            );
        }
        if (category.fourthApp.length > 0) {
            icons.push(
                prepareCategoryAppIcons(
                    category.fourthApp,
                    category.type === BaseCategoryType.Group
                )
            );
        }
        if (category.fifthApp.length > 0) {
            icons.push(
                prepareCategoryAppIcons(
                    category.fifthApp,
                    category.type === BaseCategoryType.Group
                )
            );
        }
        if (category.sixthApp.length > 0) {
            icons.push(
                prepareCategoryAppIcons(
                    category.sixthApp,
                    category.type === BaseCategoryType.Group
                )
            );
        }
        if (category.finalApp.length > 0) {
            icons.push(
                prepareCategoryAppIcons(
                    category.finalApp,
                    category.type === BaseCategoryType.Group
                )
            );
        }

        return icons;
    }

    function prepareGroupCategorySection(
        category: RhythmicGymnasticsCategoryDetails
    ) {
        let targetTeams = event?.teams.filter(
            (x) => x.categoryId === category.id
        );
        if (targetTeams && targetTeams.length > 0) {
            targetTeams.forEach((team) => {
                team.members = filterParticipiants(team.members);
            });
            if (sortByDate !== SortingOption.None) {
                targetTeams.forEach((team) => {
                    team.members = team.members.sort((a, b) => {
                        if (sortByDate === SortingOption.Ascending) {
                            return a.createdDate > b.createdDate ? 1 : -1;
                        } else {
                            return a.createdDate > b.createdDate ? -1 : 1;
                        }
                    });
                });
            } else if (sortByName !== SortingOption.None) {
                targetTeams.forEach((team) => {
                    team.members = team.members.sort((a, b) => {
                        if (sortByName === SortingOption.Ascending) {
                            return a.lastName.localeCompare(b.lastName);
                        } else {
                            return b.lastName.localeCompare(a.lastName);
                        }
                    });
                });
            }

            return (
                <React.Fragment key={category.title}>
                    <thead
                        style={{ backgroundColor: '#C3DEEB' }}
                        key={`${category.title}-head`}
                    >
                        <tr>
                            <th colSpan={7}>
                                <div className="d-flex align-items-center">
                                    {category.title} {category.yearOfBirth}{' '}
                                    {prepareCategoryApps(category)}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {targetTeams.map((team, i) => (
                            <EventTeamComponent
                                team={team}
                                index={i}
                                key={`${team.name}-${i}`}
                            />
                        ))}
                    </tbody>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    function prepareCategorySection(
        category: RhythmicGymnasticsCategoryDetails
    ) {
        let targetParticipiants = event?.participiants.filter(
            (x) =>
                x.categoryId === category.id &&
                x.type === RhytmicGymnasticsEventParticipiant.Gymnast
        );

        if (targetParticipiants && targetParticipiants.length > 0) {
            targetParticipiants = filterParticipiants(targetParticipiants);
        }

        if (targetParticipiants && targetParticipiants.length > 0) {
            if (sortByDate !== SortingOption.None) {
                targetParticipiants = targetParticipiants.sort((a, b) => {
                    if (sortByDate === SortingOption.Ascending) {
                        return a.createdDate > b.createdDate ? 1 : -1;
                    } else {
                        return a.createdDate > b.createdDate ? -1 : 1;
                    }
                });
            } else if (sortByName !== SortingOption.None) {
                targetParticipiants = targetParticipiants.sort((a, b) => {
                    if (sortByName === SortingOption.Ascending) {
                        return a.lastName.localeCompare(b.lastName);
                    } else {
                        return b.lastName.localeCompare(a.lastName);
                    }
                });
            }

            return (
                <React.Fragment key={category.title}>
                    <thead
                        style={{ backgroundColor: '#C3DEEB' }}
                        key={`${category.title}-head`}
                    >
                        <tr>
                            <th colSpan={8}>
                                <div className="d-flex align-items-center">
                                    {category.title} {category.yearOfBirth}{' '}
                                    {prepareCategoryApps(category)}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody key={`${category.title}-body`}>
                        {targetParticipiants.map((part, i) => (
                            <tr key={part.firstName + '-' + part.lastName}>
                                <td>{i + 1}</td>
                                <td>{part.licenseNumber}</td>
                                <td>{`${part.lastName} ${part.firstName}`}</td>
                                <td>{`${part.city} ${part.club}`}</td>
                                <td>
                                    <Flag
                                        code={countryList().getValue(
                                            part.country
                                        )}
                                        height={16}
                                        width={24}
                                    />{' '}
                                    {countryList().getValue(part.country)}
                                </td>
                                <td>
                                    {new Date(part.birthDate).getFullYear() !==
                                    1
                                        ? new Date(part.birthDate).getFullYear()
                                        : ''}
                                </td>
                                <td>
                                    {part.photo ? (
                                        <img
                                            className="participiant-table-photo"
                                            alt="profile"
                                            src={prepareProfilePhotoUrl(
                                                part.photo
                                            )}
                                        />
                                    ) : (
                                        <div
                                            style={{ height: 36, width: 36 }}
                                        ></div>
                                    )}
                                </td>
                                <td>
                                    {event?.registrationDeadline &&
                                        new Date(
                                            event.registrationDeadline
                                        ).getTime() > Date.now() &&
                                        (user?.role === UserRole.ADMIN ||
                                            part.createdUserId ===
                                                user?.id) && (
                                            <Pencil
                                                cursor="pointer"
                                                color="#086A98"
                                                onClick={() => {
                                                    history.push(
                                                        `${location.pathname}/participiants/${part.id}`
                                                    );
                                                }}
                                            />
                                        )}
                                </td>
                            </tr>
                        ))}
                        <tr></tr>
                    </tbody>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    function prepareOtherTypeSections(
        type: RhytmicGymnasticsEventParticipiant
    ) {
        let targetParticipiants = event?.participiants.filter(
            (x) => x.type === type
        );

        if (targetParticipiants && targetParticipiants.length > 0) {
            targetParticipiants = filterParticipiants(targetParticipiants);
        }
        if (targetParticipiants && targetParticipiants.length > 0) {
            if (sortByDate !== SortingOption.None) {
                targetParticipiants = targetParticipiants.sort((a, b) => {
                    if (sortByDate === SortingOption.Ascending) {
                        return a.createdDate > b.createdDate ? 1 : -1;
                    } else {
                        return a.createdDate > b.createdDate ? -1 : 1;
                    }
                });
            } else if (sortByName !== SortingOption.None) {
                targetParticipiants = targetParticipiants.sort((a, b) => {
                    if (sortByName === SortingOption.Ascending) {
                        return a.lastName.localeCompare(b.lastName);
                    } else {
                        return b.lastName.localeCompare(a.lastName);
                    }
                });
            }

            return (
                <React.Fragment key={type}>
                    <thead
                        style={{ backgroundColor: '#C3DEEB' }}
                        key={`${type}-head`}
                    >
                        <tr>
                            <th colSpan={7}>
                                <div className="d-flex align-items-center">
                                    {
                                        rhytmicGymnasticsEventParticipiantMultipleOptions.find(
                                            (x) => x.value === type
                                        )?.text
                                    }
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody key={`${type}-body`}>
                        {targetParticipiants.map((part, i) => (
                            <tr key={part.firstName + '-' + part.lastName}>
                                <td>{i + 1}</td>
                                <td>{part.licenseNumber}</td>
                                <td>{`${part.lastName} ${part.firstName}`}</td>
                                <td>{`${part.city} ${part.club}`}</td>
                                <td>
                                    <Flag
                                        code={countryList().getValue(
                                            part.country
                                        )}
                                        height={16}
                                        width={24}
                                    />{' '}
                                    {countryList().getValue(part.country)}
                                </td>
                                <td>
                                    {new Date(part.birthDate).getFullYear() !==
                                    1
                                        ? new Date(part.birthDate).getFullYear()
                                        : ''}
                                </td>
                                <td>
                                    {part.photo ? (
                                        <img
                                            className="participiant-table-photo"
                                            alt="profile"
                                            src={prepareProfilePhotoUrl(
                                                part.photo
                                            )}
                                        />
                                    ) : (
                                        <div
                                            style={{ height: 36, width: 36 }}
                                        ></div>
                                    )}
                                </td>
                            </tr>
                        ))}
                        <tr></tr>
                    </tbody>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    function prepareParticipiantsTable() {
        let subTables = [];
        let individual: JSX.Element[] = [];
        let group: JSX.Element[] = [];
        event?.categories.forEach((category) => {
            if (category.type === BaseCategoryType.Individual) {
                const res = prepareCategorySection(category);
                if (res) {
                    individual.push(res);
                }
            } else {
                const res = prepareGroupCategorySection(category);
                if (res) {
                    group.push(res);
                }
            }
        });
        if (individual.length > 0) {
            subTables.push(individual);
        }
        if (group.length > 0) {
            subTables.push(group);
        }
        const coaches = prepareOtherTypeSections(
            RhytmicGymnasticsEventParticipiant.Coach
        );
        if (coaches) {
            subTables.push(coaches);
        }

        const arbiters = prepareOtherTypeSections(
            RhytmicGymnasticsEventParticipiant.Arbiter
        );
        if (arbiters) {
            subTables.push(arbiters);
        }

        const managers = prepareOtherTypeSections(
            RhytmicGymnasticsEventParticipiant.Manager
        );
        if (managers) {
            subTables.push(managers);
        }

        const medicals = prepareOtherTypeSections(
            RhytmicGymnasticsEventParticipiant.MedicalStaff
        );
        if (medicals) {
            subTables.push(medicals);
        }

        const spectators = prepareOtherTypeSections(
            RhytmicGymnasticsEventParticipiant.Spectator
        );
        if (spectators) {
            subTables.push(spectators);
        }

        const others = prepareOtherTypeSections(
            RhytmicGymnasticsEventParticipiant.Other
        );
        if (others) {
            subTables.push(others);
        }

        return (
            <>
                <div className="d-flex flex-column align-items-end">
                    <SortOption
                        title={t('words.name')}
                        sortBy={sortByName}
                        onClick={() => {
                            setSortByDate(SortingOption.None);
                            setSortByName(changeSortingOption(sortByName));
                        }}
                    />
                    <SortOption
                        title={t('phrases.registrationDate')}
                        sortBy={sortByDate}
                        onClick={() => {
                            setSortByName(SortingOption.None);
                            setSortByDate(changeSortingOption(sortByDate));
                        }}
                    />
                    <div style={{ marginBottom: 30 }}>
                        <Button
                            outline={
                                _.isEqual(filter, initailFilterValues)
                                    ? true
                                    : false
                            }
                            color="primary"
                            type="button"
                            onClick={() => setShowFilters(true)}
                        >
                            <Funnel />
                            {t('words.filter').toUpperCase()}
                        </Button>
                    </div>
                    {filter.lastName && (
                        <SelectedFilterOption target="lastName" />
                    )}
                    {filter.country && (
                        <SelectedFilterOption target="country" />
                    )}
                    {filter.yearOfBirth && (
                        <SelectedFilterOption target="yearOfBirth" />
                    )}
                    {filter.category && (
                        <SelectedFilterOption target="category" />
                    )}
                    {filter.type && (
                        <div className="participiants-filter-item">
                            {
                                rhytmicGymnasticsEventParticipiantMultipleOptions.find(
                                    (x) => x.value === filter.type
                                )?.text
                            }{' '}
                            <img
                                src={images.icons.closeCross}
                                onClick={() =>
                                    setFilter({ ...filter, type: null })
                                }
                                alt="close-cross"
                            />
                        </div>
                    )}
                </div>
                {subTables.length > 0 ? (
                    <div className="full-screen-table-container">
                        <Table
                            striped
                            borderless
                            className="event-participiants-table"
                        >
                            {subTables.map((x) => x)}
                        </Table>
                    </div>
                ) : (
                    <p>{t('phrases.noEntries')}</p>
                )}
                <Modal
                    isOpen={showFilters}
                    toggle={() => setShowFilters(false)}
                    centered
                >
                    <ModalHeader
                        toggle={() => setShowFilters(false)}
                        close={closeBtn}
                    ></ModalHeader>
                    <ModalBody style={{ textAlign: 'start' }}>
                        <p
                            style={{
                                textAlign: 'start',
                                fontWeight: 700,
                                marginBottom: 20,
                            }}
                        >
                            {t('phrases.chooseEventsFilters')}
                        </p>
                        <Formik
                            initialValues={filter}
                            initialErrors={{}}
                            innerRef={formRef}
                            onSubmit={(values) => {
                                setFilter({ ...values });
                            }}
                        >
                            {({
                                handleSubmit,
                                values,
                                errors,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Label for="lastName">
                                            {t('words.lastName')}
                                        </Label>
                                        <Input
                                            id="lastName"
                                            name="lastName"
                                            placeholder={t('words.lastName')}
                                            type="text"
                                            value={values.lastName}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'lastName',
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                    <CountriesSelect
                                        value={values.country}
                                        onChange={(e: string) =>
                                            setFieldValue('country', e)
                                        }
                                        error={!!errors.country}
                                    />
                                    <FormGroup>
                                        <Label for="yearOfBirth">
                                            {t('words.yearOfBirth')}
                                        </Label>
                                        <Input
                                            id="yearOfBirth"
                                            name="lastName"
                                            placeholder={t('words.yearOfBirth')}
                                            type="text"
                                            pattern="[0-9]+"
                                            value={
                                                values.yearOfBirth
                                                    ? values.yearOfBirth
                                                    : ''
                                            }
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'yearOfBirth',
                                                    +e.target.value
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="category">
                                            {t('words.category')}
                                        </Label>
                                        <BaseSelect
                                            title={t('words.category')}
                                            onChange={(
                                                category: SelectOption
                                            ) => {
                                                setFieldValue(
                                                    'category',
                                                    category.text
                                                );
                                            }}
                                            options={selectOption}
                                            value={selectOption.find(
                                                (x) =>
                                                    x.text === values.category
                                            )}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="type">
                                            {t('words.type')}
                                        </Label>
                                        <BaseSelect
                                            title={t('words.type')}
                                            options={
                                                rhytmicGymnasticsEventParticipiantOptions
                                            }
                                            value={rhytmicGymnasticsEventParticipiantOptions.find(
                                                (x) => x.value === values.type
                                            )}
                                            onChange={(value: SelectOption) => {
                                                setFieldValue(
                                                    'type',
                                                    value.value
                                                );
                                            }}
                                        />
                                    </FormGroup>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            outline
                            className="btn-inline"
                            color="secondary"
                            onClick={cleanForm}
                        >
                            {t('words.clear')}
                        </Button>
                        <Button
                            className="btn-inline"
                            color="primary"
                            onClick={submitForm}
                        >
                            {t('words.show')}
                        </Button>{' '}
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    function SelectedFilterOption(props: { target: any }) {
        return (
            <div className="participiants-filter-item">
                {
                    // @ts-ignore
                    filter[props.target]
                }{' '}
                <img
                    src={images.icons.closeCross}
                    onClick={() => setFilter({ ...filter, [props.target]: '' })}
                    alt="close-cross"
                />
            </div>
        );
    }

    useEffect(() => {
        setSelectOptions(
            event?.categories.map(
                (x) =>
                    ({
                        text: x.title,
                        value: x.title,
                    } as SelectOption)
            ) || []
        );
    }, [event, filter]);
    return event && event.participiants.length > 0 ? (
        prepareParticipiantsTable()
    ) : (
        <>{t('phrases.noEntries')}</>
    );
}
