import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import { CheckCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import api from 'config/api';
import LoadingProgress from 'components/shared/LoadingProgress';
import FullScreenMessageContainer from 'components/shared/FullScreenMessageContainer';
import { Button } from 'reactstrap';
import { ResponseError } from 'constants/interfaces';
import globalStore from 'store/globalStore';

export default function EmailConfirmationPage() {
    const [showSucces, setShowSuccess] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (!!!queryParams.get('userId')) {
            history.push('/');
        }
        history.replace({ search: '' });
        api.account
            .confirmEmail(queryParams)
            .then(() => setShowSuccess(true))
            .catch((error: ResponseError) =>
                dispatch(globalStore.actionCreators.setMessage(error))
            );
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {showSucces ? (
                <FullScreenMessageContainer>
                    <CheckCircle className="title-img" />
                    <h3>{t('phrases.thankYou')}</h3>
                    <p className="body-regular">
                        {t('phrases.succesfullyRegistered')}
                        <br />
                        {t('phrases.pleaseLogIn')}
                    </p>
                    <Button
                        block
                        color="primary"
                        type="submit"
                        onClick={() => history.push('/auth/login')}
                    >
                        {t('words.login')}
                    </Button>
                    <div className="bottom-massages-group"></div>
                </FullScreenMessageContainer>
            ) : (
                <LoadingProgress />
            )}
        </>
    );
}
