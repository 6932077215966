import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';

import images from 'assets/images';
import { RhythmicGymnasticsAppartType } from 'constants/enums';
import './selects.scss';
import { RhythmicGymnasticsAppart } from 'components/events/interfaces';

interface AppOption {
    value: RhythmicGymnasticsAppartType;
    text: string;
    icon: string;
    count: number;
}

export default function AppartsGroupSelectComponent(props: {
    title: string;
    onChange: any;
    value: RhythmicGymnasticsAppart[] | null;
}) {
    const { t } = useTranslation();
    const [isOnFocus, setIsOnFocus] = useState(false);
    const defaultData = [
        {
            value: RhythmicGymnasticsAppartType.WithoutApparatus,
            text: t('words.withoutApparatus'),
            icon: images.icons.noApp,
            count: 0,
        },
        {
            value: RhythmicGymnasticsAppartType.ByChoice,
            text: t('words.byChoice'),
            icon: images.icons.choseApp,
            count: 0,
        },
        {
            value: RhythmicGymnasticsAppartType.Rope,
            text: t('words.rope'),
            icon: images.icons.ropeApp,
            count: 0,
        },
        {
            value: RhythmicGymnasticsAppartType.Hoop,
            text: t('words.hoop'),
            icon: images.icons.hoopApp,
            count: 0,
        },
        {
            value: RhythmicGymnasticsAppartType.Ball,
            text: t('words.ball'),
            icon: images.icons.ballApp,
            count: 0,
        },
        {
            value: RhythmicGymnasticsAppartType.Clubs,
            text: t('words.clubs'),
            icon: images.icons.clubsApp,
            count: 0,
        },
        {
            value: RhythmicGymnasticsAppartType.Ribbon,
            text: t('words.ribbon'),
            icon: images.icons.ribbonApp,
            count: 0,
        },
    ];
    const [data, setData] = useState(prepareDefaultData());

    function prepareDefaultData() {
        if (!!props.value) {
            return defaultData.map((x) =>
                props.value?.find((y) => y.value === x.value)
                    ? {
                          ...x,
                          count: props.value!.find((y) => y.value === x?.value)!
                              .count,
                      }
                    : x
            );
        } else {
            return defaultData;
        }
    }

    function prepareShow() {
        const labels = data.filter((x) => x.count! > 0);
        if (labels.length > 0) {
            return labels.map((x, i) => (
                <div
                    className="d-inline-block"
                    style={{ marginRight: 10, color: 'black' }}
                    key={`${x.value}-${i}`}
                >
                    <span style={{ marginRight: 5 }}>{x.count}</span>
                    <img
                        src={x.icon}
                        alt={`group-option-${Math.random}`}
                        className="option-icon"
                    />
                </div>
            ));
        } else {
            return <span>{props.title}</span>;
        }
    }

    function handleClick(isPlus: boolean, value: RhythmicGymnasticsAppartType) {
        if (!isPlus && data.find((x) => x.value === value)?.count === 0) {
            return;
        }
        if (
            data.reduce((acc, obj) => {
                return acc + obj.count;
            }, 0) >= 5 &&
            isPlus
        ) {
            return;
        }
        const appsCount = data.filter((x) => x.count > 0);
        if (
            appsCount.length === 2 &&
            appsCount.filter((x) => x.value === value).length === 0
        ) {
            return;
        }
        const newState = data.map((x) =>
            x.value !== value
                ? x
                : { ...x, count: isPlus ? x.count + 1 : x.count - 1 }
        );
        props.onChange(newState.filter((x) => x.count > 0));
        setData(newState);
    }

    function option(props: AppOption) {
        return (
            <div
                className="d-flex justify-content-between app-option"
                key={props.value}
            >
                <div>
                    <img
                        src={props.icon}
                        alt={`option-${option.name}`}
                        className="option-icon"
                    />
                    <span>{props.text}</span>
                </div>
                <div>
                    <span
                        style={{
                            color: props.count === 0 ? '#CED4DA' : 'black',
                        }}
                    >
                        {props.count}
                    </span>
                    <div
                        className="option-counter-control"
                        onClick={() => handleClick(false, props.value)}
                    >
                        <img
                            src={images.icons.optionMinus}
                            alt="option-minus"
                        />
                    </div>
                    <div
                        className="option-counter-control"
                        onClick={() => handleClick(true, props.value)}
                    >
                        <img src={images.icons.optionPlus} alt="option-plus" />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setIsOnFocus(false)}>
            <div
                className={
                    isOnFocus ? 'form-select form-select-focus' : 'form-select'
                }
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOnFocus(true);
                }}
            >
                {prepareShow()}
                <div
                    className="form-select form-select-options-container"
                    style={{ display: isOnFocus ? 'block' : 'none' }}
                >
                    {data.map((x) => option(x))}
                </div>
            </div>
        </OutsideClickHandler>
    );
}
