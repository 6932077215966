import React from 'react';

import { useTranslation } from 'react-i18next';

import FullScreenMessageContainer from 'components/shared/FullScreenMessageContainer';
import images from 'assets/images';

export default function CheckEmailMessage() {
    const { t } = useTranslation();
    return (
        <FullScreenMessageContainer>
            <img
                className="title-img"
                src={images.icons.checkEmailIcon}
                alt="title-img"
            />
            <h3>{t('phrases.pleaseCheckEmail')}</h3>
            <p className="body-regular">{t('phrases.resetLinkWasSent')}</p>
            <div className="bottom-massages-group">
                <p className="body-regular text-center">
                    {t('phrases.backTo')}{' '}
                    <a href="/auth/login">{t('words.login')}</a>
                </p>
                <p className="body-regular text-center">
                    {t('phrases.doNotHaheAnAccount')}{' '}
                    <a href="/registration">{t('phrases.registerHere')}</a>
                </p>
            </div>
        </FullScreenMessageContainer>
    );
}
