import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    Collapse,
    Container,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import './navigation.scss';
import { ApplicationState } from 'store';
import UserNavMenu from './UserNavMenu';
import GuestNavMenu from './GuestNavMenu';
import images from 'assets/images';
import { UserRole } from 'constants/enums';
import AdminNavMenu from './AdminNavMenu';

export default function NavMenu() {
    const [isToggled, setIsToggled] = React.useState(false);

    const user = useSelector((state: ApplicationState) => state.account);

    function prepareMenu() {
        if (!!!user?.role) {
            return <GuestNavMenu handleClose={() => setIsToggled(false)} />;
        } else if (user.role === UserRole.USER) {
            return <UserNavMenu handleClose={() => setIsToggled(false)} />;
        } else if (user.role === UserRole.ADMIN) {
            return <AdminNavMenu handleClose={() => setIsToggled(false)} />;
        }
    }

    return (
        <>
            <header>
                <Navbar
                    className="navbar-expand-sm navbar-toggleable-sm mb-3 no-transition"
                    light
                >
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <img
                                src={images.mainLogo}
                                className="d-inline-block align-top"
                                alt="est events logo"
                            />
                        </NavbarBrand>
                        <NavbarToggler
                            onClick={toggle}
                            className={`mr-2 ${
                                isToggled ? 'nav-toggler-cross' : ''
                            }`}
                            //style={{ display: isToggled ? 'none' : '' }}
                        />
                        <Collapse
                            className="d-sm-inline-flex flex-sm-row "
                            isOpen={isToggled}
                            navbar
                        >
                            <Nav>
                                <NavItem onClick={toggle}>
                                    {/* <NavLink
                                        tag={Link}
                                        className="text-dark"
                                        to="/"
                                    >
                                        {t('words.hompage')}
                                    </NavLink> */}
                                </NavItem>
                                {prepareMenu()}
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        </>
    );

    function toggle() {
        setIsToggled(!isToggled);
    }
}
