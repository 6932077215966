import icons from './icons/index';
import * as mainLogo from './est-events-logo.png';
import * as mainLogoSm from './est-events-logo-sm.png';
import * as mainLogoLg from './est-events-logo-lg.png';

const images = {
    icons: { ...icons },
    mainLogo,
    mainLogoSm,
    mainLogoLg,
};

export default images;
