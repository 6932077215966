import * as React from 'react';

import AdminRouter from './AdminRouter';
import { AuthProps } from './MainRouter';
import UserRouter from './UserRouter';
import { UserRole } from 'constants/enums';

export default function AuthRouter(props: AuthProps) {
    return props.role === UserRole.ADMIN ? <AdminRouter /> : <UserRouter />;
}
