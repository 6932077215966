import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useParams, useHistory } from 'react-router-dom';
import Flag from 'react-world-flags';
import countryList from 'react-select-country-list';
import { useTranslation } from 'react-i18next';
import { Button, Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap';

import EventTypeLabel from '../components/EventTypeLabel';
import { formatDate } from 'helpers/dateTimeHelper';
import EventDocumentsInfoblock from '../components/EventDocumentsInfoblock';
import EventDetailsInfoblock from '../components/EventDetailsInfoblock';
import EventCategoriesTable from '../components/EventCategoriesTable';
import EventParticipiants from '../components/EventParticipiants';
import { UserRole, RhytmicGymnasticsEventStatus } from 'constants/enums';
import { actionCreators as eventsActions } from 'store/eventsStore/actionCreators';
import { actionCreators as globalActions } from 'store/globalStore/actionCreators';
import { ApplicationState } from 'store';
import EventEditPage from './EventEditPage';
import ConfirmationModal from 'components/shared/ConfirmationModal';
import EventLogo from 'components/shared/EventLogo';
import { BoxArrowRight } from 'react-bootstrap-icons';
import api from 'config/api';

export default function EventDetailsPage() {
    let { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.account);
    const event = useSelector(
        (state: ApplicationState) => state.events?.eventDetails
    );
    const [showEditPage, setShowEditPage] = useState(false);
    const [needToRefresh, setNeedToRefresh] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [currentActiveTab, setCurrentActiveTab] = useState(1);
    const history = useHistory();

    function postEvent() {
        if (id) {
            dispatch(eventsActions.postEvent(id, user?.role));
        }
    }

    function deleteEvent() {
        if (id) {
            dispatch(eventsActions.deleteEvent(id));
        }
    }

    function downloadEventMediaFiles() {
        dispatch(globalActions.showLoader());
        api.files.downloadEventMediaFiles(event!.title).finally(() => {
            dispatch(globalActions.hideLoader());
        });
    }

    function downloadEventEntriesList() {
        dispatch(globalActions.showLoader());
        api.files
            .downloadEventEntriesList(event!.title, event!.id)
            .finally(() => {
                dispatch(globalActions.hideLoader());
            });
    }

    useEffect(() => {
        let isApiSubscribed = true;
        if (id || (needToRefresh && id)) {
            if (isApiSubscribed) {
                dispatch(eventsActions.getRhytmicGymnastyEventDetails(id));
                setNeedToRefresh(false);
            }
        }
        return () => {
            isApiSubscribed = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, needToRefresh]);
    return event ? (
        showEditPage ? (
            <EventEditPage
                event={{ ...event }}
                onClose={() => {
                    setShowEditPage(false);
                    setNeedToRefresh(true);
                }}
            />
        ) : (
            <>
                {user?.role === UserRole.ADMIN &&
                    event.status === RhytmicGymnasticsEventStatus.Active && (
                        <>
                            <div style={{ height: 120, marginBottom: 75 }}>
                                <div className="admin-edit-container">
                                    <div className="container d-flex justify-content-between">
                                        <Button
                                            className="btn-inline"
                                            outline
                                            color="primary"
                                            onClick={() =>
                                                setShowConfirmationModal(true)
                                            }
                                        >
                                            {t('words.remove').toUpperCase()}
                                        </Button>
                                        <Button
                                            className="btn-inline"
                                            color="primary"
                                            onClick={() =>
                                                setShowEditPage(true)
                                            }
                                        >
                                            {t('words.edit').toUpperCase()}
                                        </Button>
                                    </div>
                                    <div className="container d-flex justify-content-end">
                                        <div style={{ marginTop: 24 }}>
                                            <Button
                                                outline
                                                color="primary"
                                                type="button"
                                                className="admin-download-btn"
                                                onClick={
                                                    downloadEventMediaFiles
                                                }
                                            >
                                                <BoxArrowRight />
                                                {t('phrases.audioFiles')}
                                            </Button>
                                            <Button
                                                outline
                                                color="primary"
                                                type="button"
                                                className="admin-download-btn"
                                                onClick={
                                                    downloadEventEntriesList
                                                }
                                            >
                                                <BoxArrowRight />
                                                {t('phrases.entriesList')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ConfirmationModal
                                isOpen={showConfirmationModal}
                                onClose={() => setShowConfirmationModal(false)}
                                action={deleteEvent}
                                text={t('phrases.doYouWantDeleteEvent')}
                                actionText={t('words.delete')}
                            />
                        </>
                    )}
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column justify-content-between align-items-start">
                        <Flag
                            code={countryList().getValue(event.country)}
                            height={34}
                        />
                        <EventTypeLabel type={event.type} noMargin={true} />
                    </div>
                    <EventLogo src={event.logo} isDetails={true} />
                </div>
                <h4 style={{ margin: '32px 0 20px 0' }}>{event.title}</h4>
                <EventDetailsInfoblock
                    title={`${t('words.dates')}:`}
                    text={`${formatDate(event.startDate)} - ${formatDate(
                        event.endDate
                    )}`}
                    oneLine={true}
                    boldText={true}
                    increaseTextSize={true}
                />
                <EventDetailsInfoblock
                    title={`${t('words.place')}:`}
                    text={event.place}
                />
                <EventDetailsInfoblock
                    title={`${t('words.organizer')}:`}
                    text={event.organizer}
                />
                <EventDetailsInfoblock
                    title={`${t('words.registrationDeadline')}:`}
                    text={`${formatDate(event.registrationDeadline)}`}
                    boldText={true}
                    oneLine={true}
                />
                <EventDetailsInfoblock
                    title={`${t('words.audioDeadline')}:`}
                    text={`${formatDate(event.audioDeadline)}`}
                    oneLine={true}
                />
                <EventDetailsInfoblock
                    title={`${t('words.contacts')}:`}
                    text={event.contacts}
                />
                <EventDetailsInfoblock
                    title={`${t('words.notes')}:`}
                    text={event.notes}
                />
                <EventDocumentsInfoblock
                    eventId={event.id}
                    documents={event.documents}
                />
                <Nav tabs className="event-participiant-tab">
                    <NavItem>
                        <NavLink
                            className={currentActiveTab === 1 ? 'active' : ''}
                            onClick={() => setCurrentActiveTab(1)}
                        >
                            {t('words.categories').toUpperCase()}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={currentActiveTab === 2 ? 'active' : ''}
                            onClick={() => setCurrentActiveTab(2)}
                        >
                            {t('words.entries').toUpperCase()}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent
                    activeTab={currentActiveTab}
                    className="bg-transparent p-0"
                >
                    <TabPane tabId={1}>
                        <EventCategoriesTable
                            categories={event.categories}
                            hideActions={true}
                        />
                    </TabPane>
                    <TabPane tabId={2}>
                        <EventParticipiants />
                    </TabPane>
                </TabContent>
                <div>
                    {new Date(event.startDate).getTime() < new Date().getTime()}
                </div>
                {event.status === RhytmicGymnasticsEventStatus.Active &&
                    new Date(event.startDate).getTime() >
                        new Date().getTime() && (
                        <>
                            <Button
                                block
                                outline
                                color="primary"
                                type="button"
                                onClick={() =>
                                    history.push(
                                        `/events/${id}/group-registration`
                                    )
                                }
                            >
                                {t('words.toRegister')}
                                <br />
                                <span>{t('phrases.participantsGroup')}</span>
                            </Button>
                            <Button
                                block
                                color="primary"
                                onClick={() =>
                                    history.push(`/events/${id}/registration`)
                                }
                                style={{ margin: '24px 0 32px 0' }}
                            >
                                {t('words.toRegister')}
                                <br />
                                <span>
                                    {t('phrases.participantRefereeCoach')}
                                </span>
                            </Button>
                        </>
                    )}

                {user?.role === UserRole.ADMIN &&
                    event.status ===
                        RhytmicGymnasticsEventStatus.WaitingForApproval && (
                        <>
                            <br />
                            <Button
                                block
                                outline
                                color="primary"
                                type="button"
                                onClick={() => setShowEditPage(true)}
                            >
                                {t('words.edit').toUpperCase()}
                            </Button>
                            <Button
                                block
                                color="primary"
                                onClick={postEvent}
                                style={{ margin: '24px 0 32px 0' }}
                            >
                                {t('words.post').toUpperCase()}
                            </Button>
                        </>
                    )}
            </>
        )
    ) : null;
}
