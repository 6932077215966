import React, { useState } from 'react';

import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import EmailLoginForm from './components/EmailLoginForm';
import PhoneNumberForm from './components/PhoneNumberForm';
import ProposalFooter from '../shared/ProposalFooter';
import './login.scss';

export default function LogInPage() {
    const { t } = useTranslation();
    const [currentActiveTab, setCurrentActiveTab] = useState(1);

    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <h4 className="mt-30">{t('words.login')}</h4>
                <p className="body-small text-center">
                    {t('phrases.pleaseLogInInYourAccount')}
                </p>
                <Nav tabs className="login-nav-tab">
                    <NavItem>
                        <NavLink
                            className={currentActiveTab === 1 ? 'active' : ''}
                            onClick={() => setCurrentActiveTab(1)}
                        >
                            {t('words.email')}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={currentActiveTab === 2 ? 'active' : ''}
                            onClick={() => setCurrentActiveTab(2)}
                        >
                            {t('words.phoneNumber')}
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={currentActiveTab}>
                    <TabPane tabId={1}>
                        <EmailLoginForm />
                    </TabPane>
                    <TabPane tabId={2}>
                        <PhoneNumberForm />
                    </TabPane>
                </TabContent>
                <br />
                <ProposalFooter
                    text={t('phrases.doNotHaheAnAccount')}
                    link={t('phrases.registerHere')}
                    goTo="registration"
                />
            </div>
        </div>
    );
}
