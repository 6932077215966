import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Label, Input } from 'reactstrap';
import { Plus, Trash3 } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

import './shared.scss';
import { actionCreators } from 'store/globalStore/actionCreators';

interface ImageUploadField {
    title?: string;
    file: File | string | null;
    fileName?: string;
    onChange: (file: File | string | null) => void;
}
export function ImageUploadField(props: ImageUploadField) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [choosedLogo, setChoosedLogo] = useState<string>('');
    const [choosedLogoName, setChoosedLogoName] = useState<string>(
        prepareFileNameFromProps()
    );
    const SIZE_LIMIT = 1024 * 5;
    const ACCEPTED_TYPES = ['image/gif', 'image/jpeg', 'image/png'];

    function prepareFileFromProps() {
        if (props.file) {
            if (typeof props.file === 'string') {
                return props.file;
            } else if (typeof props.file === 'object') {
                return URL.createObjectURL(props.file);
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    function prepareFileNameFromProps() {
        if (props.fileName) {
            return props.fileName;
        } else if (props.file && typeof props.file === 'object') {
            return props.file.name;
        } else {
            return 'photo.jpg';
        }
    }

    const changeHandler = (event: any) => {
        if (event.target.files[0].size / 1024 > SIZE_LIMIT) {
            dispatch(
                actionCreators.setMessage({
                    status: 500,
                    title: '',
                    message: t('warnings.imageSizeWarning', {
                        size: 5,
                    }),
                })
            );
        } else if (!ACCEPTED_TYPES.includes(event.target.files[0]['type'])) {
            dispatch(
                actionCreators.setMessage({
                    status: 500,
                    title: '',
                    message: t('warnings.fileShouldBeImage'),
                })
            );
        } else {
            props.onChange(event.target.files[0]);
            setChoosedLogoName(event.target.files[0].name);
            const imageSrc = URL.createObjectURL(event.target.files[0]);
            setChoosedLogo(imageSrc);
        }
    };

    function clearLogo() {
        props.onChange(null);
        setChoosedLogo('');
        setChoosedLogoName('');
    }

    useEffect(() => {
        setChoosedLogo(prepareFileFromProps());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.file]);

    return (
        <>
            <div
                className={`upload-img-container ${
                    choosedLogo ? 'upload-img-container_selected' : ''
                }`}
            >
                {props.file && choosedLogo ? (
                    <>
                        <div
                            className="upload-img-preview"
                            style={{ backgroundImage: `url(${choosedLogo})` }}
                        ></div>
                        <div className="image-item">
                            <span>{choosedLogoName}</span>
                            <div>
                                <Trash3
                                    className="file-remove-btn"
                                    onClick={() => clearLogo()}
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <Label for="image-upload" className="upload-img-btn">
                        <Plus />
                        <div>{props.title?.toUpperCase()}</div>
                    </Label>
                )}
                <Input
                    id="image-upload"
                    accept="image/*"
                    type="file"
                    name="file"
                    onChange={changeHandler}
                    multiple
                />
            </div>
        </>
    );
}
