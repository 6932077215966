import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Upload, Trash3 } from 'react-bootstrap-icons';
import { Input, Label } from 'reactstrap';

import { RhythmicGymnasticsAppart, ApparatusMediaFile } from '../../interfaces';
import { actionCreators } from 'store/globalStore/actionCreators';
import { individualAppsOptions } from 'constants/variables';
import { RhythmicGymnasticsAppartType } from 'constants/enums';
import AppartsSelectComponent from '../components/AppartsSelectComponent';
import { appartTypeNameHelper } from 'helpers/functions';

interface CategoryAppFileUploadProps {
    apps: RhythmicGymnasticsAppart[];
    appsNumber: number;
    onAdd: (file: ApparatusMediaFile) => void;
    onRemove: (app: string) => void;
    mediaUploadEndDate?: Date;
    zIndex?: number;
}

export default function CategoryAppFileUpload(
    props: CategoryAppFileUploadProps
) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [file, setFile] = useState<File | null>(null);
    const [apps, setApps] = useState<RhythmicGymnasticsAppart[]>([]);
    const [choosedApp, setChoosedApp] = useState<RhythmicGymnasticsAppartType>(
        RhythmicGymnasticsAppartType.ByChoice
    );
    const SIZE_LIMIT = 1024 * 5;
    const ACCEPTED_TYPES = ['audio/mpeg'];

    function prepareAppView(apps: RhythmicGymnasticsAppart[]) {
        const result: RhythmicGymnasticsAppart[] = [];
        apps.forEach((app) => {
            let targetApp = individualAppsOptions.find(
                (op) => op.value === app.value
            );
            if (targetApp) {
                result.push({ ...targetApp, count: app.count });
            }
        });
        return result;
    }

    function changeHandler(event: any, app: string) {
        if (event.target.files[0]) {
            if (event.target.files[0].size / 1024 > SIZE_LIMIT) {
                dispatch(
                    actionCreators.setMessage({
                        status: 500,
                        title: '',
                        message: t('warnings.imageSizeWarning', {
                            size: 5,
                        }),
                    })
                );
            } else if (
                !ACCEPTED_TYPES.includes(event.target.files[0]['type'])
            ) {
                dispatch(
                    actionCreators.setMessage({
                        status: 500,
                        title: '',
                        message: t('warnings.fileShouldBeAudio'),
                    })
                );
            } else {
                //props.onChange(event.target.files[0]);
                //setChoosedLogoName(event.target.files[0].name);
                //const imageSrc = URL.createObjectURL(event.target.files[0]);
                setFile(event.target.files[0]);
                props.onAdd({
                    app: app,
                    file: event.target.files[0],
                });
                //setIsFilePicked(true);
                //setChoosedLogo(imageSrc);
            }
        }
    }

    function removeFile(app: string) {
        setFile(null);
        props.onRemove(app);
    }

    function prepareFileName(name: string) {
        if (name.length > 40) {
            return '...' + name.slice(-22);
        } else {
            return name;
        }
    }

    function prepareUploadField() {
        return props.mediaUploadEndDate &&
            new Date(props.mediaUploadEndDate).getTime() > Date.now() ? (
            <div>
                <Label for={`file-upload-${props.appsNumber}`}>
                    <Upload
                        style={{ cursor: 'pointer' }}
                        height={24}
                        width={24}
                    />
                </Label>
                <Input
                    id={`file-upload-${props.appsNumber}`}
                    type="file"
                    name="file"
                    onChange={(e) =>
                        changeHandler(
                            e,
                            apps[0].value ===
                                RhythmicGymnasticsAppartType.ByChoice
                                ? appartTypeNameHelper(choosedApp)
                                : apps[0].text
                        )
                    }
                />
            </div>
        ) : (
            <div>
                <Label
                    for={`file-upload-${props.appsNumber}`}
                    onClick={() =>
                        dispatch(
                            actionCreators.setMessage({
                                status: 500,
                                title: '',
                                message: t(
                                    'warnings.mediaUoloadDeadlineExpired'
                                ),
                            })
                        )
                    }
                >
                    <Upload
                        style={{ cursor: 'pointer' }}
                        height={24}
                        width={24}
                    />
                </Label>
            </div>
        );
    }

    useEffect(() => {
        file !== null && setFile(null);
        setApps(prepareAppView(props.apps));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.apps]);

    return (
        <>
            {apps.length > 0 &&
                (apps.length === 1 ? (
                    <div
                        className="d-flex flex-alight-top flex-justify-between p-relative"
                        key={apps[0].value + Math.random() * 10}
                    >
                        <div className="event-participiant-file-upload-base">
                            {props.appsNumber === 0
                                ? t('words.finalApp')
                                : `${t('words.app')} ${props.appsNumber}`}
                        </div>
                        <div
                            className="event-participiant-file-upload p-relative"
                            style={
                                apps.some(
                                    (x) =>
                                        x.value ===
                                        RhythmicGymnasticsAppartType.ByChoice
                                )
                                    ? { zIndex: props.zIndex ?? 1 }
                                    : {}
                            }
                        >
                            <div>
                                {apps[0].value ===
                                RhythmicGymnasticsAppartType.ByChoice ? (
                                    <AppartsSelectComponent
                                        selected={choosedApp}
                                        onChange={(
                                            value: RhythmicGymnasticsAppartType
                                        ) => setChoosedApp(value)}
                                    />
                                ) : (
                                    <div className="d-flex">
                                        <img
                                            style={{ marginRight: 15 }}
                                            src={apps[0].icon}
                                            alt={`app-${Math.random}`}
                                            className="app-icon"
                                        />
                                        {apps[0].text}
                                    </div>
                                )}

                                {file ? (
                                    <div className="file-item" key={file.name}>
                                        {prepareFileName(file.name)}{' '}
                                        <Trash3
                                            className="file-remove-btn"
                                            style={{ marginLeft: 10 }}
                                            onClick={() =>
                                                removeFile(apps[0].value)
                                            }
                                        />
                                    </div>
                                ) : null}
                            </div>
                            {prepareUploadField()}
                        </div>
                    </div>
                ) : (
                    <div
                        className="d-flex flex-alight-top flex-justify-between p-relative"
                        style={
                            apps.some(
                                (x) =>
                                    x.value ===
                                    RhythmicGymnasticsAppartType.ByChoice
                            )
                                ? { zIndex: 1 }
                                : {}
                        }
                        key={apps[0].value + Math.random() * 100}
                    >
                        <div className="event-participiant-file-upload-base">
                            {props.appsNumber === 0
                                ? t('words.finalApp')
                                : `${t('words.app')} ${props.appsNumber}`}
                        </div>
                        <div className="event-participiant-file-upload">
                            <div>
                                <div
                                    className="d-flex"
                                    style={{ fontSize: 20 }}
                                >
                                    {apps.map((x, i) =>
                                        x.value ===
                                        RhythmicGymnasticsAppartType.ByChoice ? (
                                            <AppartsSelectComponent
                                                selected={choosedApp}
                                                onChange={(
                                                    value: RhythmicGymnasticsAppartType
                                                ) => setChoosedApp(value)}
                                            />
                                        ) : (
                                            <React.Fragment key={x.value + i}>
                                                {x.count}
                                                <img
                                                    style={{ marginRight: 5 }}
                                                    src={x.icon}
                                                    alt={`app-${Math.random}`}
                                                    className="app-icon"
                                                />
                                                {i === 0 && ' + '}
                                            </React.Fragment>
                                        )
                                    )}
                                </div>
                                <div
                                    className="d-flex"
                                    style={{ fontSize: 14 }}
                                >
                                    {apps.map((x, i) => (
                                        <React.Fragment key={x.value + i}>
                                            {`${x.count} ${x.text}`}
                                            {i === 0 && ' + '}
                                        </React.Fragment>
                                    ))}
                                </div>

                                {file ? (
                                    <div className="file-item" key={file.name}>
                                        {prepareFileName(file.name)}{' '}
                                        <Trash3
                                            className="file-remove-btn"
                                            style={{ marginLeft: 10 }}
                                            onClick={() =>
                                                removeFile(apps[0].value)
                                            }
                                        />
                                    </div>
                                ) : null}
                            </div>
                            {prepareUploadField()}
                        </div>
                    </div>
                ))}
        </>
    );
}
