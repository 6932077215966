import React from 'react';

import { useTranslation } from 'react-i18next';

import { EventType } from 'constants/enums';

export default function EventTypeLabel(props: {
    type: EventType;
    noMargin?: boolean;
}) {
    const { t } = useTranslation();
    const eventTypeOptions = [
        {
            value: EventType.Live,
            text: t('words.live'),
            color: '#53BA6B',
        },
        {
            value: EventType.Virtual,
            text: t('words.virtual'),
            color: '#EED033',
        },
    ];

    const event = () => eventTypeOptions.find((x) => x.value === props.type);
    return (
        <div
            className="event-type-label"
            style={{
                backgroundColor: event()?.color,
                marginLeft: props.noMargin ? 0 : 37,
            }}
        >
            {event()?.text}
        </div>
    );
}
