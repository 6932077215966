import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';

import images from 'assets/images';
import { RhythmicGymnasticsAppartType } from 'constants/enums';
import '../../../events/newEvent/components/selects/selects.scss';
import { appartTypeNameHelper, prepareAppIcon } from 'helpers/functions';

interface AppOption {
    value: RhythmicGymnasticsAppartType;
    text: string;
    icon: string;
    count: number;
}

export default function AppartsSelectComponent(props: {
    onChange: any;
    selected: RhythmicGymnasticsAppartType;
}) {
    const { t } = useTranslation();
    const [isOnFocus, setIsOnFocus] = useState(false);
    const defaultData = [
        {
            value: RhythmicGymnasticsAppartType.WithoutApparatus,
            text: t('words.withoutApparatus'),
            icon: images.icons.noApp,
            count: 0,
        },
        {
            value: RhythmicGymnasticsAppartType.Rope,
            text: t('words.rope'),
            icon: images.icons.ropeApp,
            count: 0,
        },
        {
            value: RhythmicGymnasticsAppartType.Hoop,
            text: t('words.hoop'),
            icon: images.icons.hoopApp,
            count: 0,
        },
        {
            value: RhythmicGymnasticsAppartType.Ball,
            text: t('words.ball'),
            icon: images.icons.ballApp,
            count: 0,
        },
        {
            value: RhythmicGymnasticsAppartType.Clubs,
            text: t('words.clubs'),
            icon: images.icons.clubsApp,
            count: 0,
        },
        {
            value: RhythmicGymnasticsAppartType.Ribbon,
            text: t('words.ribbon'),
            icon: images.icons.ribbonApp,
            count: 0,
        },
    ];

    function prepareShow() {
        return (
            <span>
                {props.selected !== RhythmicGymnasticsAppartType.ByChoice ? (
                    <div className="d-flex">
                        <img
                            style={{ marginRight: 15 }}
                            src={prepareAppIcon(props.selected)}
                            alt={`choosed-${appartTypeNameHelper(
                                props.selected
                            )}-app`}
                            className="app-icon"
                        />
                        {appartTypeNameHelper(props.selected)}
                    </div>
                ) : (
                    'Оберіть предмет'
                )}
            </span>
        );
    }

    function handleClick(value: RhythmicGymnasticsAppartType) {
        props.onChange(value);
    }

    function option(props: AppOption) {
        return (
            <div
                className="d-flex justify-content-between app-option"
                key={props.value}
            >
                <div onClick={() => handleClick(props.value)}>
                    <img
                        src={props.icon}
                        alt={`option-${option.name}`}
                        className="option-icon"
                    />
                    <span>{props.text}</span>
                </div>
            </div>
        );
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setIsOnFocus(false)}>
            <div
                className={
                    isOnFocus ? 'form-select form-select-focus' : 'form-select'
                }
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOnFocus(true);
                }}
                style={{ minWidth: 156 }}
            >
                {prepareShow()}
                <div
                    className="form-select form-select-options-container"
                    style={{ display: isOnFocus ? 'block' : 'none' }}
                >
                    {defaultData.map((x) => option(x))}
                </div>
            </div>
        </OutsideClickHandler>
    );
}
