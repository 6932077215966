import * as React from 'react';
import { Container } from 'reactstrap';

import NavMenu from 'components/navigation/NavMenu';
import LoadingProgress from './shared/LoadingProgress';
import NotificationsToast from './shared/NotificationsToast';
import Footer from './Footer';

export default function Layout(props: any) {
    return (
        <React.Fragment>
            <NavMenu />
            <Container>
                <LoadingProgress />
                <NotificationsToast />
                {props.children}
                {/* <Footer /> */}
            </Container>
        </React.Fragment>
    );
}
