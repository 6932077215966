import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Button,
    FormGroup,
    Form,
    FormFeedback,
    Label,
    Input,
    Row,
    Col,
} from 'reactstrap';

import { FileUploadField } from 'components/shared/FilesUploadField';
import { ImageUploadField } from 'components/shared/ImageUploadField';
import { EventType } from 'constants/enums';
import CountriesSelect from 'components/shared/CountriesSelect';
import CategoriesComponents from '../newEvent/components/CategoriesComponent';
import BaseSelect, {
    SelectOption,
} from '../newEvent/components/selects/BaseSelect';
import {
    RhythmicGymnasticsEventModel,
    RhythmicGymnasticsCategoryModel,
} from 'config/api/models';
import { actionCreators as globalActions } from 'store/globalStore/actionCreators';
import { individualAppsOptions } from 'constants/variables';
import api from 'config/api';
import {
    RhythmicGymnasticsEventDetailsEdit,
    RhythmicGymnasticsCategory,
    RhythmicGymnasticsCategoryDetails,
} from '../interfaces';

interface EventEditPageProps {
    event: RhythmicGymnasticsEventDetailsEdit;
    onClose?: () => void;
}

export default function EventEditPage(props: EventEditPageProps) {
    const dispatch = useDispatch();
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [documents, setDocuments] = useState<File[]>(
        props.event.documents.map((d) => new File([], d))
    );
    const [logo, setLogo] = useState<File | string | null>(props.event.logo);
    const isLogoChanged = useRef(false);
    const [categories, setCategories] = useState<RhythmicGymnasticsCategory[]>(
        prepareCategories()
    );
    const { t } = useTranslation();
    const formRef = useRef(null);

    function prepareCategories() {
        let categories = [...props.event.categories];
        categories.forEach((x) => {
            x.firstApp = x.firstApp.map((y) => ({
                value: y.value,
                count: y.count,
                text:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.text ?? '',
                icon:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.icon ?? '',
            }));
            x.secondApp = x.secondApp.map((y) => ({
                value: y.value,
                count: y.count,
                text:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.text ?? '',
                icon:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.icon ?? '',
            }));
            x.thirdApp = x.thirdApp.map((y) => ({
                value: y.value,
                count: y.count,
                text:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.text ?? '',
                icon:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.icon ?? '',
            }));
            x.fourthApp = x.fourthApp.map((y) => ({
                value: y.value,
                count: y.count,
                text:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.text ?? '',
                icon:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.icon ?? '',
            }));
            x.fifthApp = x.fifthApp.map((y) => ({
                value: y.value,
                count: y.count,
                text:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.text ?? '',
                icon:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.icon ?? '',
            }));
            x.sixthApp = x.sixthApp.map((y) => ({
                value: y.value,
                count: y.count,
                text:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.text ?? '',
                icon:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.icon ?? '',
            }));
            x.fifthApp = x.finalApp.map((y) => ({
                value: y.value,
                count: y.count,
                text:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.text ?? '',
                icon:
                    individualAppsOptions.find((z) => z.value === y.value)
                        ?.icon ?? '',
            }));
        });

        return categories;
    }

    const schema = Yup.object().shape({
        title: Yup.string().required(
            t('errors.required', {
                name: t('words.title'),
            })
        ),
        country: Yup.string().required(
            t('errors.required', {
                name: t('words.country'),
            })
        ),
        place: Yup.string().required(
            t('errors.required', {
                name: t('words.place'),
            })
        ),
        organizer: Yup.string().required(
            t('errors.required', {
                name: t('words.organizer'),
            })
        ),
        contacts: Yup.string().required(
            t('errors.required', {
                name: t('words.contacts'),
            })
        ),
    });

    const competitionTypeOptions = [
        {
            value: EventType.Live,
            text: t('words.live'),
        },
        {
            value: EventType.Virtual,
            text: t('words.virtual'),
        },
    ];

    function submitForm() {
        if (formRef.current) {
            // @ts-ignore
            formRef.current.handleSubmit();
        }
    }

    function saveCategory(category: RhythmicGymnasticsCategory) {
        const updatedCategories = [...categories, category];
        localStorage.setItem(
            'est-events-categories',
            JSON.stringify(updatedCategories)
        );
        setCategories(updatedCategories);
    }

    function updateCategory(
        category:
            | RhythmicGymnasticsCategoryDetails
            | RhythmicGymnasticsCategory,
        index: number
    ) {
        const updatedCategories = categories.map((x, i) =>
            i !== index ? x : category
        );
        setCategories(updatedCategories);
    }

    function removeCategory(index: number) {
        const updatedCategories = categories.filter((x, i) => i !== index);
        setCategories(updatedCategories);
    }

    function changeLogo(file: File | string | null) {
        setLogo(file);
        isLogoChanged.current = true;
    }

    return (
        <Row>
            <Col md={{ size: 6, offset: 3 }}>
                <h4>{t('words.editEvent')}</h4>
                <Formik
                    initialValues={{
                        ...props.event,
                        startDate: props.event.startDate
                            .toString()
                            .substr(0, 10),
                        endDate: props.event.endDate.toString().substr(0, 10),
                        audioDeadline: props.event.audioDeadline
                            .toString()
                            .substr(0, 10),
                        registrationDeadline: props.event.registrationDeadline
                            .toString()
                            .substr(0, 10),
                    }}
                    validationSchema={schema}
                    validateOnChange={validateOnChange}
                    initialErrors={{}}
                    innerRef={formRef}
                    onSubmit={async (values) => {
                        dispatch(globalActions.showLoader());
                        const model: RhythmicGymnasticsEventModel = {
                            type: values.type,
                            title: values.title,
                            startDate: new Date(values.startDate),
                            endDate: new Date(values.endDate),
                            country: values.country,
                            place: values.place,
                            organizer: values.organizer,
                            registrationDeadline: new Date(
                                values.registrationDeadline
                            ),
                            audioDeadline: new Date(values.audioDeadline),
                            contacts: values.contacts,
                            notes: values.notes,
                            logo: '',
                            categories: [],
                        };

                        categories.forEach((category) => {
                            const ct: RhythmicGymnasticsCategoryModel = {
                                id: category.id,
                                title: category.title,
                                type: category.type,
                                yearOfBirth: category.yearOfBirth,
                                apparatuses: [],
                            };
                            category.firstApp.forEach((app) =>
                                ct.apparatuses.push({
                                    name: 'FirstApp',
                                    apparatus: app.value,
                                    count: app.count,
                                })
                            );
                            category.secondApp.forEach((app) =>
                                ct.apparatuses.push({
                                    name: 'SecondApp',
                                    apparatus: app.value,
                                    count: app.count,
                                })
                            );
                            category.thirdApp.forEach((app) =>
                                ct.apparatuses.push({
                                    name: 'ThirdApp',
                                    apparatus: app.value,
                                    count: app.count,
                                })
                            );
                            category.fourthApp.forEach((app) =>
                                ct.apparatuses.push({
                                    name: 'FourthApp',
                                    apparatus: app.value,
                                    count: app.count,
                                })
                            );
                            category.fifthApp.forEach((app) =>
                                ct.apparatuses.push({
                                    name: 'FifthApp',
                                    apparatus: app.value,
                                    count: app.count,
                                })
                            );
                            category.sixthApp.forEach((app) =>
                                ct.apparatuses.push({
                                    name: 'SixthApp',
                                    apparatus: app.value,
                                    count: app.count,
                                })
                            );
                            category.finalApp.forEach((app) =>
                                ct.apparatuses.push({
                                    name: 'FinalApp',
                                    apparatus: app.value,
                                    count: app.count,
                                })
                            );
                            model.categories.push(ct);
                        });
                        await api.events.updateEvent(props.event.id, model);
                        let promises = [];
                        if (isLogoChanged.current) {
                            if (!logo) {
                                const deleteLogo = api.files
                                    .deleteEventLogo(props.event.id)
                                    .then(() => setLogo(null))
                                    .catch((error) =>
                                        dispatch(
                                            globalActions.setMessage({
                                                status: error.status,
                                                title: error.title,
                                                message: error.message,
                                            })
                                        )
                                    );
                                promises.push(deleteLogo);
                            } else {
                                const logoFormData = new FormData();
                                logoFormData.append('File', logo!);
                                const updateLogo = api.files
                                    .updateEventLogo(
                                        props.event.id,
                                        logoFormData
                                    )
                                    .then((response) => {
                                        setLogo(response);
                                    })
                                    .catch((error) =>
                                        dispatch(
                                            globalActions.setMessage({
                                                status: error.status,
                                                title: error.title,
                                                message: error.message,
                                            })
                                        )
                                    );
                                promises.push(updateLogo);
                            }
                        }

                        const newDocuments = documents.filter(
                            (x) => !props.event.documents.includes(x.name)
                        );
                        if (newDocuments.length > 0) {
                            const docsFormData = new FormData();
                            newDocuments.forEach((doc) =>
                                docsFormData.append('File', doc)
                            );
                            const uploadDocuments = api.files
                                .uploadEventDocuments(
                                    props.event.id,
                                    docsFormData
                                )
                                .catch((error) =>
                                    dispatch(
                                        globalActions.setMessage({
                                            status: error.status,
                                            title: error.title,
                                            message: error.message,
                                        })
                                    )
                                );
                            promises.push(uploadDocuments);
                        }

                        const newDocumentsNames = documents.map((x) => x.name);
                        const documentsToDelete = props.event.documents.filter(
                            (x) => !newDocumentsNames.includes(x)
                        );
                        if (documentsToDelete.length > 0) {
                            const deleteDocuments = api.files
                                .deleteEventDocuments(
                                    props.event.id,
                                    documentsToDelete
                                )
                                .catch((error) =>
                                    dispatch(
                                        globalActions.setMessage({
                                            status: error.status,
                                            title: error.title,
                                            message: error.message,
                                        })
                                    )
                                );
                            promises.push(deleteDocuments);
                        }

                        Promise.all(promises)
                            .then(() => {
                                dispatch(
                                    globalActions.setMessage({
                                        status: 200,
                                        title: '',
                                        message: t('phrases.eventUpdated'),
                                    })
                                );
                                props.onClose && props.onClose();
                            })
                            .finally(() =>
                                dispatch(globalActions.hideLoader())
                            );
                    }}
                >
                    {({ handleSubmit, values, errors, setFieldValue }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label for="type">{t('words.type')}*</Label>
                                <BaseSelect
                                    title={t('words.type')}
                                    options={competitionTypeOptions}
                                    value={competitionTypeOptions.find(
                                        (x) => x.value === values.type
                                    )}
                                    onChange={(value: SelectOption) => {
                                        setFieldValue('type', value.value);
                                    }}
                                />
                                <FormFeedback>{errors.type}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="title">{t('words.title')}*</Label>
                                <Input
                                    id="title"
                                    name="title"
                                    placeholder={t('words.title')}
                                    type="text"
                                    value={values.title}
                                    onChange={(e) => {
                                        setFieldValue('title', e.target.value);
                                    }}
                                    invalid={!!errors.title}
                                />
                                <FormFeedback>{errors.title}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="startDate">
                                    {t('words.startDate')}*
                                </Label>
                                <Input
                                    id="startDate"
                                    name="startDate"
                                    type="date"
                                    // @ts-ignore
                                    value={values.startDate}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'startDate',
                                            e.target.value
                                        );
                                    }}
                                    invalid={!!errors.startDate}
                                />
                                <FormFeedback>{errors.startDate}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="endDate">
                                    {t('words.endDate')}*
                                </Label>
                                <Input
                                    id="endDate"
                                    name="endDate"
                                    type="date"
                                    // @ts-ignore
                                    value={values.endDate}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'endDate',
                                            e.target.value
                                        );
                                    }}
                                    invalid={!!errors.endDate}
                                />
                                <FormFeedback>{errors.endDate}</FormFeedback>
                            </FormGroup>
                            <CountriesSelect
                                value={values.country}
                                onChange={(e: string) =>
                                    setFieldValue('country', e)
                                }
                                error={!!errors.country}
                            />
                            <FormGroup>
                                <Label for="place">{t('words.place')}*</Label>
                                <Input
                                    id="place"
                                    name="place"
                                    placeholder={t('words.place')}
                                    type="text"
                                    value={values.place}
                                    onChange={(e) => {
                                        setFieldValue('place', e.target.value);
                                    }}
                                    invalid={!!errors.place}
                                />
                                <FormFeedback>{errors.place}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="organizer">
                                    {t('words.organizer')}*
                                </Label>
                                <Input
                                    id="organizer"
                                    name="organizer"
                                    placeholder={t('words.organizer')}
                                    type="text"
                                    value={values.organizer}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'organizer',
                                            e.target.value
                                        );
                                    }}
                                    invalid={!!errors.organizer}
                                />
                                <FormFeedback>{errors.organizer}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="registrationDeadline">
                                    {t('words.registrationDeadline')}*
                                </Label>
                                <Input
                                    id="registrationDeadline"
                                    name="registrationDeadline"
                                    placeholder={t(
                                        'words.registrationDeadline'
                                    )}
                                    type="date"
                                    // @ts-ignore
                                    value={values.registrationDeadline}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'registrationDeadline',
                                            e.target.value
                                        );
                                    }}
                                    invalid={!!errors.registrationDeadline}
                                />
                                <FormFeedback>
                                    {errors.registrationDeadline}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="audioDeadline">
                                    {t('words.audioDeadline')}*
                                </Label>
                                <Input
                                    id="audioDeadline"
                                    name="audioDeadline"
                                    placeholder={t('words.audioDeadline')}
                                    type="date"
                                    // @ts-ignore
                                    value={values.audioDeadline}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'audioDeadline',
                                            e.target.value
                                        );
                                    }}
                                    invalid={!!errors.audioDeadline}
                                />
                                <FormFeedback>
                                    {errors.registrationDeadline}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="contacts">
                                    {t('words.contacts')}*
                                </Label>
                                <Input
                                    id="contacts"
                                    name="contacts"
                                    placeholder={t('words.contacts')}
                                    type="textarea"
                                    value={values.contacts}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'contacts',
                                            e.target.value
                                        );
                                    }}
                                    invalid={!!errors.contacts}
                                />
                                <FormFeedback>{errors.contacts}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="notes">{t('words.notes')}</Label>
                                <Input
                                    id="notes"
                                    name="notes"
                                    placeholder={t('words.notes')}
                                    type="textarea"
                                    value={values.notes}
                                    onChange={(e) => {
                                        setFieldValue('notes', e.target.value);
                                    }}
                                    invalid={!!errors.notes}
                                />
                                <FormFeedback>{errors.notes}</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label>{t('words.documents')}</Label>
                                <FileUploadField
                                    title={t('words.addFile')}
                                    onChange={setDocuments}
                                    files={documents}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>{t('words.addLogo')}</Label>
                                <ImageUploadField
                                    title={t('words.logo')}
                                    onChange={changeLogo}
                                    file={logo}
                                    fileName={props.event.logoName}
                                />
                            </FormGroup>
                        </Form>
                    )}
                </Formik>
                <CategoriesComponents
                    categories={categories}
                    onSave={saveCategory}
                    onDelete={removeCategory}
                    onEdit={updateCategory}
                />
                <Button
                    block
                    color="primary"
                    onClick={() => {
                        setValidateOnChange(true);
                        submitForm();
                    }}
                >
                    {t('words.save')}
                </Button>
                <Button
                    block
                    outline
                    style={{ margin: '12px 0' }}
                    color="secondary"
                    onClick={props.onClose}
                >
                    {t('words.cancel')}
                </Button>
            </Col>
        </Row>
    );
}
