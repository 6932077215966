import React from 'react';

import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import parse from 'html-react-parser';

import { categoryTypeNameHelper, prepareAppName } from 'helpers/functions';
import { RhythmicGymnasticsCategory } from '../interfaces';

interface EventCategoriesTableProps {
    categories: RhythmicGymnasticsCategory[];
    onUpdate?: (i: number, category: RhythmicGymnasticsCategory) => void;
    onDelete?: (i: number) => void;
    hideActions?: boolean;
}

export default function EventCategoriesTable(props: EventCategoriesTableProps) {
    const { t } = useTranslation();

    return (
        <div className="full-screen-table-container">
            <Table striped borderless>
                <thead style={{ color: '#6C757D' }}>
                    <tr>
                        <th>{t('words.category')}</th>
                        <th>{t('words.type')}</th>
                        <th>{t('words.yearOfBirth')}</th>
                        <th>1 {t('words.app')}</th>
                        <th>2 {t('words.app')}</th>
                        <th>3 {t('words.app')}</th>
                        <th>4 {t('words.app')}</th>
                        <th>5 {t('words.app')}</th>
                        <th>6 {t('words.app')}</th>
                        <th>{t('words.finalApp')}</th>
                        {!props.hideActions && (
                            <>
                                <th></th>
                                <th></th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.categories
                        ?.sort((a, b) =>
                            a.yearOfBirth > b.yearOfBirth ? 1 : -1
                        )
                        .map((category, i) => (
                            <tr key={category.title + i}>
                                <td>{category.title}</td>
                                <td>{categoryTypeNameHelper(category.type)}</td>
                                <td>{category.yearOfBirth}</td>
                                <td>
                                    {parse(
                                        prepareAppName(
                                            category.firstApp,
                                            category.type
                                        )
                                    )}
                                </td>
                                <td>
                                    {parse(
                                        prepareAppName(
                                            category.secondApp,
                                            category.type
                                        )
                                    )}
                                </td>
                                <td>
                                    {parse(
                                        prepareAppName(
                                            category.thirdApp,
                                            category.type
                                        )
                                    )}
                                </td>
                                <td>
                                    {parse(
                                        prepareAppName(
                                            category.fourthApp,
                                            category.type
                                        )
                                    )}
                                </td>
                                <td>
                                    {parse(
                                        prepareAppName(
                                            category.fifthApp,
                                            category.type
                                        )
                                    )}
                                </td>
                                <td>
                                    {parse(
                                        prepareAppName(
                                            category.sixthApp,
                                            category.type
                                        )
                                    )}
                                </td>
                                <td>
                                    {parse(
                                        prepareAppName(
                                            category.finalApp,
                                            category.type
                                        )
                                    )}
                                </td>
                                {!props.hideActions && (
                                    <>
                                        <td
                                            className="table-action-cell"
                                            onClick={() => {
                                                props.onUpdate &&
                                                    props.onUpdate(i, category);
                                            }}
                                        >
                                            {t('words.edit')}
                                        </td>
                                        <td
                                            className="table-action-cell"
                                            onClick={() =>
                                                props.onDelete &&
                                                props.onDelete(i)
                                            }
                                        >
                                            {t('words.delete')}
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                </tbody>
            </Table>
        </div>
    );
}
