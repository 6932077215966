import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as english from './locales/en/translation.json';
import * as ukrainian from './locales/uk/translation.json';

i18n
    // Enables the hook initialization module
    .use(initReactI18next)
    // Enables the i18next backend
    //.use(Backend)
    // Enable automatic language detection
    .use(LanguageDetector)

    .init({
        // Standard language used
        fallbackLng: 'uk',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: { translation: english.translation },
            uk: { translation: ukrainian.translation },
        },
        /* react: {
            wait: true,
            useSuspense: true,
        }, */
        /* detection: {
            order: ['queryString', 'cookie'],
            cache: ['no-store'],
        }, */
        /*  backend: {
            loadPath: '../src/config/language/locales/{{lng}}/{{ns}}.json',
            loadPath: '/locales copy/{{lng}}/{{ns}}.json',
            requestOptions: {
                cache: 'no-store',
            },
            queryStringParams: { [key: string]: string };
        }, */
        //Detects and caches a cookie from the language provided
        /* detection: {
      order: ['queryString', 'cookie'],
      cache: ['cookie']
    },react: {
     wait: true,
     useSuspense: true,
  },
    interpolation: {
      escapeValue: false
    } 
    } */
    });

export default i18n;
