import * as React from 'react';

import GuestRouter from './GuestRouter';
import AuthRouter from './AuthRouter';

export interface AuthProps {
    role: string | undefined;
}

export default function MainRoute(props: AuthProps) {
    return !!props?.role ? <AuthRouter role={props.role} /> : <GuestRouter />;
}
