import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';

export default function SendRecoveryRequest(props: {
    action: (email: string) => void;
}) {
    const { t } = useTranslation();
    const [validateOnChange, setValidateOnChange] = useState(false);

    const schema = Yup.object().shape({
        email: Yup.string()
            .email(t('errors.email'))
            .required(
                t('errors.required', {
                    name: t('words.email'),
                })
            ),
    });

    return (
        <div className="row">
            <h4 className="mt-30">{t('phrases.forgotYourPassword')}</h4>
            <p className="body-small text-center">
                {t('phrases.enterEmailForPasswordRecovery')}
            </p>
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={schema}
                validateOnChange={validateOnChange}
                initialErrors={{}}
                onSubmit={(values) => {
                    props.action(values.email);
                }}
            >
                {({ handleSubmit, values, errors, setFieldValue }) => (
                    <Form
                        onSubmit={handleSubmit}
                        className="col-md-6 offset-md-3"
                    >
                        <FormGroup>
                            <Label for="email">{t('words.email')}</Label>
                            <Input
                                id="email"
                                name="email"
                                placeholder={t('words.email')}
                                type="text"
                                value={values.email}
                                onChange={(e) =>
                                    setFieldValue('email', e.target.value)
                                }
                                invalid={!!errors.email}
                            />
                            <FormFeedback>{errors.email}</FormFeedback>
                        </FormGroup>

                        <Button
                            block
                            color="primary"
                            type="submit"
                            onClick={() => setValidateOnChange(true)}
                        >
                            {t('phrases.sendResetLink').toUpperCase()}
                        </Button>
                    </Form>
                )}
            </Formik>
            <br />
            <div className="bottom-massages-group">
                <p className="body-regular text-center">
                    {t('phrases.backTo')}{' '}
                    <a href="auth/login">{t('words.login')}</a>
                </p>
                <p className="body-regular text-center">
                    {t('phrases.doNotHaheAnAccount')}{' '}
                    <a href="/registration">{t('phrases.registerHere')}</a>
                </p>
            </div>
        </div>
    );
}
