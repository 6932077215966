import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useParams, useHistory } from 'react-router-dom';
import Flag from 'react-world-flags';
import countryList from 'react-select-country-list';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

import api from 'config/api';
import EventTypeLabel from '../components/EventTypeLabel';
import { formatDate } from 'helpers/dateTimeHelper';
import EventDocumentsInfoblock from '../components/EventDocumentsInfoblock';
import EventDetailsInfoblock from '../components/EventDetailsInfoblock';
import EventCategoriesTable from '../components/EventCategoriesTable';
import { actionCreators as globalActions } from 'store/globalStore/actionCreators';
import { ResponseError } from 'constants/interfaces';
import EventEditPage from '../eventDetails/EventEditPage';
import images from 'assets/images';
import { RhytmicGymnasticsEventStatus } from 'constants/enums';
import EventLogo from 'components/shared/EventLogo';
import ConfirmationModal from 'components/shared/ConfirmationModal';
import { RhythmicGymnasticsEventDetailsEdit } from '../interfaces';

export default function UserEventDetailsPage() {
    let { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [event, setEvent] =
        useState<RhythmicGymnasticsEventDetailsEdit | null>(null);
    const [showEditPage, setShowEditPage] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const history = useHistory();

    function postEvent() {
        if (id) {
            dispatch(globalActions.showLoader());
            api.events
                .postEvent(id)
                .then((result) => {
                    setEvent({ ...event!, status: +result });
                    dispatch(
                        globalActions.setMessage({
                            status: 200,
                            title: '',
                            message: t('phrases.eventPostedForReview'),
                        })
                    );
                })
                .catch((error: ResponseError) =>
                    dispatch(globalActions.setMessage(error))
                )
                .finally(() => dispatch(globalActions.hideLoader()));
        }
    }

    function deleteEvent() {
        if (id) {
            dispatch(globalActions.showLoader());
            api.events
                .deleteEvent(id)
                .then(() => {
                    dispatch(
                        globalActions.setMessage({
                            status: 200,
                            title: '',
                            message: t('phrases.eventRemoved'),
                        })
                    );
                    history.goBack();
                })
                .catch((error: ResponseError) =>
                    dispatch(globalActions.setMessage(error))
                )
                .finally(() => dispatch(globalActions.hideLoader()));
        }
    }

    useEffect(() => {
        let isApiSubscribed = true;
        if (id) {
            dispatch(globalActions.showLoader());
            api.events
                .getEventDetails(id)
                .then((response) => {
                    isApiSubscribed && setEvent(response);
                })
                .catch((error: ResponseError) =>
                    dispatch(globalActions.setMessage(error))
                )
                .finally(() => dispatch(globalActions.hideLoader()));
        }
        return () => {
            isApiSubscribed = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return event ? (
        showEditPage ? (
            <EventEditPage
                event={{ ...event }}
                onClose={() => setShowEditPage(false)}
            />
        ) : (
            <>
                {event.status ===
                    RhytmicGymnasticsEventStatus.WaitingForApproval && (
                    <div className="waiting-for-approval-message">
                        <img
                            src={images.icons.attentionIcon}
                            alt="attention-icon.svg"
                        />
                        <div>{t('phrases.eventIsWaitingForApproval')}</div>
                    </div>
                )}
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column justify-content-between align-items-start">
                        <EventTypeLabel type={event.type} noMargin={true} />
                        <Flag
                            code={countryList().getValue(event.country)}
                            height={34}
                        />
                    </div>
                    <EventLogo src={event.logo} isDetails={true} />
                </div>
                <h4 style={{ margin: '24px 0 20px 0' }}>{event.title}</h4>
                <EventDetailsInfoblock
                    title={`${t('words.dates')}:`}
                    text={`${formatDate(event.startDate)} - ${formatDate(
                        event.endDate
                    )}`}
                    oneLine={true}
                    boldText={true}
                    increaseTextSize={true}
                />
                <EventDetailsInfoblock
                    title={`${t('words.place')}:`}
                    text={event.place}
                />
                <EventDetailsInfoblock
                    title={`${t('words.organizer')}:`}
                    text={event.organizer}
                />
                <EventDetailsInfoblock
                    title={`${t('words.registrationDeadline')}:`}
                    text={`${formatDate(event.registrationDeadline)}`}
                    boldText={true}
                    oneLine={true}
                />
                <EventDetailsInfoblock
                    title={`${t('words.audioDeadline')}:`}
                    text={`${formatDate(event.audioDeadline)}`}
                    oneLine={true}
                />
                <EventDetailsInfoblock
                    title={`${t('words.contacts')}:`}
                    text={event.contacts}
                />
                <EventDetailsInfoblock
                    title={`${t('words.notes')}:`}
                    text={event.notes}
                />
                <EventDocumentsInfoblock
                    eventId={event.id}
                    documents={event.documents}
                />
                <EventCategoriesTable
                    categories={event.categories}
                    hideActions={true}
                />
                <br />
                <Button
                    block
                    outline
                    color="primary"
                    type="button"
                    disabled={
                        event.status !== RhytmicGymnasticsEventStatus.Draft &&
                        event.status !== RhytmicGymnasticsEventStatus.Active
                    }
                    onClick={() => setShowEditPage(true)}
                >
                    {t('words.edit').toUpperCase()}
                </Button>
                <Button
                    block
                    outline
                    style={{ margin: '12px 0' }}
                    color="secondary"
                    onClick={() => setShowConfirmationModal(true)}
                >
                    {t('words.delete')}
                </Button>
                {event.status === RhytmicGymnasticsEventStatus.Draft && (
                    <Button
                        block
                        color="primary"
                        onClick={postEvent}
                        style={{ margin: '0 0 32px 0' }}
                    >
                        {t('words.post')}
                    </Button>
                )}

                <ConfirmationModal
                    isOpen={showConfirmationModal}
                    onClose={() => setShowConfirmationModal(false)}
                    action={deleteEvent}
                    text={t('phrases.doYouWantDeleteEvent')}
                    actionText={t('words.delete')}
                />
            </>
        )
    ) : null;
}
