import React, { useMemo } from 'react';

import countryList from 'react-select-country-list';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { InputProps } from 'constants/interfaces';

export default function CountriesSelect(props: InputProps) {
    const { t } = useTranslation();
    const options = useMemo(() => countryList().getLabels(), []);

    return (
        <FormGroup>
            <Label for="country">{t('words.country')}</Label>
            <Input
                id="country"
                name="country"
                type="select"
                className="form-select"
                style={!props.value ? { color: '#6c757d' } : {}}
                searchable="true"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                invalid={props.error}
            >
                <option hidden>{t('phrases.selectCountry')}</option>
                {options.map((option) => (
                    <option key={option}>{option}</option>
                ))}
            </Input>
            <FormFeedback>
                {t('errors.required', {
                    name: t('words.country'),
                })}
            </FormFeedback>
        </FormGroup>
    );
}
