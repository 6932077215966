import React from 'react';

import { useTranslation } from 'react-i18next';

import api from 'config/api';

interface EventDocumentsInfoblockProps {
    eventId: string;
    documents: string[];
}

export default function EventDocumentsInfoblock(
    props: EventDocumentsInfoblockProps
) {
    const { t } = useTranslation();
    return (
        <div className="event-details-documents-container">
            <h6>{t('words.documents')}:</h6>
            {props.documents.map((doc) => (
                <p
                    key={doc}
                    onClick={() =>
                        api.files.downloadEventDocument(props.eventId, doc)
                    }
                >
                    {doc}
                </p>
            ))}
        </div>
    );
}
