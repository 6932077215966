import { Action, Reducer } from 'redux';
import { AccountActions } from './actions';
import { UserRole } from 'constants/enums';
import { localStorageVariables } from 'constants/variables';

export interface AccountState {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role?: UserRole;
}

const defaultState: AccountState = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
};

export const reducer: Reducer<AccountState> = (
    state: AccountState | undefined,
    incomingAction: Action
): AccountState => {
    if (state === undefined) {
        return defaultState;
    }

    const action = incomingAction as AccountActions;
    switch (action.type) {
        case 'ACCOUNT_SET_DATA':
            return action.payload;
        case 'ACCOUNT_GET_DATA_SUCCESS':
            if (!action.payload.firstName) {
                localStorage.removeItem(localStorageVariables.isUserAuth);
            }
            return action.payload;
        case 'ACCOUNT_LOG_IN_SUCCESS':
            localStorage.setItem(localStorageVariables.isUserAuth, 'true');
            return action.payload;
        case 'ACCOUNT_LOG_OUT_SUCCESS':
            localStorage.removeItem(localStorageVariables.isUserAuth);
            return defaultState;
    }

    return state;
};
