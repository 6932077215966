import * as React from 'react';

import i18next from 'i18next';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

export default function LanguageSwitcher() {
    const [isOpen, setIsOpen] = React.useState(false);
    const languages = ['uk', 'en'];

    function toggle(event: React.SyntheticEvent) {
        event.preventDefault();
        setIsOpen(!isOpen);
    }

    function changeLanguage(event: React.SyntheticEvent, lang: string) {
        event.preventDefault();
        i18next.changeLanguage(lang);
    }

    return (
        <Dropdown
            className="text-dark"
            nav
            isOpen={isOpen}
            toggle={(event: React.SyntheticEvent) => toggle(event)}
        >
            <DropdownToggle caret nav className="text-dark">
                {i18next.language === 'uk' ? 'UK' : 'EN'}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-center">
                {languages
                    .filter((language) => language !== i18next.language)
                    .map((lang) => (
                        <DropdownItem
                            onClick={(event: React.SyntheticEvent) => {
                                changeLanguage(event, lang);
                            }}
                            key={lang}
                            className="text-dark"
                        >
                            {lang.toUpperCase()}
                        </DropdownItem>
                    ))}
            </DropdownMenu>
        </Dropdown>
    );
}
