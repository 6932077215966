import * as React from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from 'store';
import { Spinner } from 'reactstrap';

export default function LoadingProgress() {
    const isLoading = useSelector(
        (state: ApplicationState) => state.global?.isLoading
    );
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                position: 'fixed',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0,0,0,0.2)',
                zIndex: 999,
                display: isLoading ? 'flex' : 'none',
            }}
        >
            <Spinner children="" />
        </div>
    );
}
