import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Toast, ToastBody } from 'reactstrap';

import { ApplicationState } from 'store';
import { actionCreators } from 'store/globalStore/actionCreators';
import images from 'assets/images';

export default function NotificationsToast() {
    const dispatch = useDispatch();
    const message = useSelector(
        (state: ApplicationState) => state.global?.message
    );

    const timeout = useRef<any>(null);

    useEffect(() => {
        timeout.current = setTimeout(() => {
            dispatch(actionCreators.clearMessage());
        }, 3000);
        return () => clearTimeout(timeout.current);
    });
    return (
        <Toast
            isOpen={message?.status !== 0}
            className={
                message?.status === 200 ? 'toast-success' : 'toast-error'
            }
        >
            <ToastBody>
                <img
                    src={
                        message?.status === 200
                            ? images.icons.checkedCircleSuccess
                            : images.icons.checkedCircleError
                    }
                    alt="check-mark"
                />
                {message?.status === 400
                    ? 'Wrong data provided'
                    : message?.message}
            </ToastBody>
        </Toast>
    );
}
