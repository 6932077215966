import React from 'react';

interface EventInfoblockProps {
    title: string;
    text: string;
    rightAlign?: boolean;
    rightPadding?: boolean;
}

export default function EventInfoblock(props: EventInfoblockProps) {
    const textAlign = props.rightAlign ? 'end' : 'start';
    const rightPadding = props.rightPadding ? 40 : 0;
    return (
        <div
            className="event-infoblock-container"
            style={{ paddingRight: rightPadding }}
        >
            <h6 style={{ textAlign: textAlign }}>{props.title}</h6>
            <p style={{ textAlign: textAlign }}>{props.text}</p>
        </div>
    );
}
