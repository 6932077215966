import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    InputGroup,
    Button,
} from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

import { UserPhoneLoginModel } from 'config/api/models';
import accountStore from 'store/accountStore';
import CountriesSelect from 'components/shared/CountriesSelect';
import PhoneInputField from 'components/shared/PhoneInputField';
import { ApplicationState } from 'store';

export default function PhoneNumberForm() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userCountry = useSelector(
        (state: ApplicationState) => state.global?.countryName
    );
    const [isPassword, setIsPassword] = React.useState(true);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const returnUrl = queryParams.get('returnUrl');

    const schema = Yup.object().shape({
        country: Yup.string().required(
            t('errors.required', {
                name: t('words.country'),
            })
        ),
        phoneNumber: Yup.string().required(
            t('errors.required', {
                name: t('words.phoneNumber'),
            })
        ),
        password: Yup.string().required(
            t('errors.required', {
                name: t('words.password'),
            })
        ),
    });

    function logIn(model: UserPhoneLoginModel) {
        dispatch(accountStore.actionCreators.logInWithPhone(model, returnUrl));
    }
    return (
        <Formik
            initialValues={{
                country: userCountry ?? '',
                phoneNumber: '',
                password: '',
                rememberMe: false,
            }}
            validationSchema={schema}
            validateOnChange={validateOnChange}
            initialErrors={{}}
            onSubmit={(values) => {
                logIn({
                    phoneNUmber: values.phoneNumber,
                    password: values.password,
                    rememberMe: values.rememberMe,
                });
            }}
        >
            {({ handleSubmit, values, errors, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                    <CountriesSelect
                        value={values.country}
                        onChange={(e: string) => {
                            setFieldValue('country', e);
                        }}
                        error={!!errors.country}
                    />
                    <PhoneInputField
                        value={values.phoneNumber}
                        onChange={(e: string) =>
                            setFieldValue('phoneNumber', e)
                        }
                        error={!!errors.phoneNumber}
                        country={values.country}
                        isRequired={true}
                    />
                    <FormGroup>
                        <Label for="password">{t('words.password')}</Label>
                        <InputGroup>
                            <Input
                                name="password"
                                placeholder={t('words.password')}
                                type={isPassword ? 'password' : 'text'}
                                value={values.password}
                                style={{ backgroundImage: 'none' }}
                                onChange={(e) =>
                                    setFieldValue('password', e.target.value)
                                }
                                invalid={!!errors.password}
                            />
                            <FormFeedback>{errors.password}</FormFeedback>
                            <div
                                className="input-group-text"
                                onClick={() => setIsPassword(!isPassword)}
                            >
                                {isPassword ? <Eye /> : <EyeSlash />}
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <div className="d-flex justify-content-between align-items-center">
                        <FormGroup check inline>
                            <Input
                                type="checkbox"
                                checked={values.rememberMe}
                                onChange={() =>
                                    setFieldValue(
                                        'rememberMe',
                                        !values.rememberMe
                                    )
                                }
                            />
                            <Label check>{t('words.rememberMe')}</Label>
                        </FormGroup>
                        <a href="auth/password-recovery">
                            {t('phrases.forgotPassword')}
                        </a>
                    </div>
                    <Button
                        block
                        color="primary"
                        type="submit"
                        onClick={() => setValidateOnChange(true)}
                    >
                        {t('words.login')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
