import {
    BaseCategoryType,
    RhythmicGymnasticsAppartType,
    SortingOption,
} from 'constants/enums';
import i18n from 'config/language/i118n';
import { RhythmicGymnasticsAppart } from 'components/events/interfaces';
import { individualAppsOptions } from 'constants/variables';
import images from 'assets/images';

export function getKeyByValue(object: any, value: string) {
    return Object.keys(object).find((key) => object[key] === value);
}

export function categoryTypeNameHelper(type: BaseCategoryType) {
    return type === BaseCategoryType.Individual
        ? i18n.t('words.individual')
        : i18n.t('words.group');
}

export function appartTypeNameHelper(
    type: RhythmicGymnasticsAppartType | undefined
) {
    if (!type) {
        return '-';
    }
    switch (type) {
        case RhythmicGymnasticsAppartType.WithoutApparatus:
            return i18n.t('words.withoutApparatus');
        case RhythmicGymnasticsAppartType.ByChoice:
            return i18n.t('words.byChoice');
        case RhythmicGymnasticsAppartType.Rope:
            return i18n.t('words.rope');
        case RhythmicGymnasticsAppartType.Hoop:
            return i18n.t('words.hoop');
        case RhythmicGymnasticsAppartType.Ball:
            return i18n.t('words.ball');
        case RhythmicGymnasticsAppartType.Clubs:
            return i18n.t('words.clubs');
        case RhythmicGymnasticsAppartType.Ribbon:
            return i18n.t('words.ribbon');
        default:
            return '-';
    }
}

export function checkFileChange(prevFile: File, newFile: File) {
    return prevFile.name !== newFile.name || prevFile.size !== newFile.size;
}

export function prepareAppName(
    apps: RhythmicGymnasticsAppart[],
    type: BaseCategoryType
) {
    if (type === BaseCategoryType.Group) {
        return apps.some((x) => x.count > 0)
            ? `<div class=d-flex>${apps
                  .map(
                      (x, i) =>
                          `<span class='d-flex align-items-center' style='margin-right:5px; font-weight: bold;' key=${`app-${x.value}-${i}`} >${
                              x.count
                          } <img style='height:24px; margin-left: 3px' class='svg-black-filter' src=${prepareAppIcon(
                              x.value
                          )} alt='app-icon'/></span>`
                  )
                  .join('')}</div>`
            : '';
    } else {
        return apps[0]?.value &&
            apps[0]?.value !== RhythmicGymnasticsAppartType.None
            ? `<img style='height: 24px;' class='svg-black-filter' src=${prepareAppIcon(
                  apps[0].value
              )} alt='app-icon'/>`
            : '';
        //return appartTypeNameHelper(apps[0]?.value);
    }
}

export function prepareAppIcon(app: RhythmicGymnasticsAppartType) {
    return individualAppsOptions.find((x) => x.value === app)?.icon;
}

export function prepareProfilePhotoUrl(name: string) {
    return `${process.env.REACT_APP_STATIC_FILES_BASE_URL}/Images/ProfileLogo/${name}`;
}

export function changeSortingOption(sortBy: SortingOption) {
    switch (sortBy) {
        case SortingOption.None:
            return SortingOption.Ascending;
        case SortingOption.Ascending:
            return SortingOption.Descending;
        case SortingOption.Descending:
            return SortingOption.None;
        default:
            return SortingOption.None;
    }
}

export function prepareSortingIcon(sortBy: SortingOption) {
    switch (sortBy) {
        case SortingOption.None:
            return images.icons.disabledArrow;
        case SortingOption.Ascending:
            return images.icons.ascArrow;
        case SortingOption.Descending:
            return images.icons.descArrow;
        default:
            return images.icons.disabledArrow;
    }
}
