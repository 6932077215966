import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import SendRecoveryRequest from './SendRecoveryRequest';
import SetNewPasswordRequest from './SetNewPasswordRequest';
import SuccessRecoveryMessage from './SuccessRecoveryMessage';
import CheckEmailMessage from './CheckEmailMessage';
import api from 'config/api';
import LoadingProgress from 'components/shared/LoadingProgress';
import globalStore from 'store/globalStore';
import { ResponseError } from 'constants/interfaces';

enum PageContent {
    Loader,
    ForgorPassword,
    ResetPassword,
    SuccessMessage,
    CheckEmail,
}

export default function PasswordRecoveryPage() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [currentView, setCurrentView] = useState(PageContent.Loader);
    const queryParams = useRef<URLSearchParams | null>(null);
    function requestRecoveryEmail(email: string) {
        dispatch(globalStore.actionCreators.showLoader());
        api.account
            .forgotPassword(email)
            .then(() => {
                setCurrentView(PageContent.CheckEmail);
            })
            .catch((error: ResponseError) =>
                dispatch(globalStore.actionCreators.setMessage(error))
            )
            .finally(() => dispatch(globalStore.actionCreators.hideLoader()));
    }
    function setNewPassword(password: string) {
        dispatch(globalStore.actionCreators.showLoader());
        api.account
            .resetPassword(queryParams.current!, password)
            .then((response) => {
                setCurrentView(PageContent.SuccessMessage);
            })
            .catch((error: ResponseError) =>
                dispatch(globalStore.actionCreators.setMessage(error))
            )
            .finally(() => dispatch(globalStore.actionCreators.hideLoader()));
    }

    function renderView() {
        switch (currentView) {
            case PageContent.Loader:
                return <LoadingProgress />;
            case PageContent.ForgorPassword:
                return <SendRecoveryRequest action={requestRecoveryEmail} />;
            case PageContent.ResetPassword:
                return <SetNewPasswordRequest action={setNewPassword} />;
            case PageContent.SuccessMessage:
                return <SuccessRecoveryMessage />;
            case PageContent.CheckEmail:
                return <CheckEmailMessage />;
        }
    }

    useEffect(() => {
        queryParams.current = new URLSearchParams(location.search);
        history.replace({ search: '' });
        if (!!!queryParams.current.get('userId')) {
            setCurrentView(PageContent.ForgorPassword);
        } else {
            setCurrentView(PageContent.ResetPassword);
        }
        // eslint-disable-next-line
    }, []);
    return renderView();
}
