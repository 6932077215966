import React, { useState, useRef } from 'react';

import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
    BaseCategoryType,
    RhythmicGymnasticsAppartType,
} from 'constants/enums';
import { Formik } from 'formik';
import {
    Form,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Button,
} from 'reactstrap';
import { PlusSquare, X, Check2Square } from 'react-bootstrap-icons';

import AppartsGroupSelectComponent from './selects/AppartsGroupSelectComponent';
import BaseSelect, { SelectOption } from './selects/BaseSelect';
import images from 'assets/images';
import EventCategoriesTable from 'components/events/components/EventCategoriesTable';
import { individualAppsOptions } from 'constants/variables';
import {
    RhythmicGymnasticsCategory,
    RhythmicGymnasticsAppart,
} from 'components/events/interfaces';

interface CategoriesProps {
    categories: RhythmicGymnasticsCategory[];
    onSave: (category: RhythmicGymnasticsCategory) => void;
    onEdit: (category: RhythmicGymnasticsCategory, index: number) => void;
    onDelete: (index: number) => void;
}

export default function CategoriesComponents(props: CategoriesProps) {
    const { t } = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const [updateCategoryIndex, setUpdateCategoryIndex] = useState(-1);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const [showMoreApps, setShowMoreApps] = useState<boolean>(false);
    const defaultFormValues: RhythmicGymnasticsCategory = {
        title: '',
        type: BaseCategoryType.Individual,
        yearOfBirth: '',
        firstApp: [
            {
                value: RhythmicGymnasticsAppartType.None,
                count: 0,
                icon: images.icons.choseApp,
                text: '',
            },
        ],
        secondApp: [
            {
                value: RhythmicGymnasticsAppartType.None,
                count: 0,
                icon: '',
                text: '',
            },
        ],
        thirdApp: [
            {
                value: RhythmicGymnasticsAppartType.None,
                count: 0,
                icon: '',
                text: '',
            },
        ],
        fourthApp: [
            {
                value: RhythmicGymnasticsAppartType.None,
                count: 0,
                icon: '',
                text: '',
            },
        ],
        fifthApp: [
            {
                value: RhythmicGymnasticsAppartType.None,
                count: 0,
                icon: '',
                text: '',
            },
        ],
        sixthApp: [
            {
                value: RhythmicGymnasticsAppartType.None,
                count: 0,
                icon: '',
                text: '',
            },
        ],
        finalApp: [
            {
                value: RhythmicGymnasticsAppartType.None,
                count: 0,
                icon: '',
                text: '',
            },
        ],
    };
    const [defaultCategoryValues, setDefaultCategoryValues] =
        useState<RhythmicGymnasticsCategory>({ ...defaultFormValues });
    const formRef = useRef(null);

    const schema = Yup.object().shape({
        title: Yup.string().required(
            t('errors.required', {
                name: t('words.title'),
            })
        ),
        yearOfBirth: Yup.string().required(
            t('errors.required', {
                name: t('words.yearOfBirth'),
            })
        ),
    });

    const categoryTypeOptions = [
        {
            value: BaseCategoryType.Individual,
            text: t('words.individual'),
        },
        {
            value: BaseCategoryType.Group,
            text: t('words.group'),
        },
    ];

    function submitForm() {
        if (formRef.current) {
            setValidateOnChange(true);
            // @ts-ignore
            formRef.current.handleSubmit();
        }
    }

    function appNumberTitle(number: number) {
        return `${t('words.app')} ${number}`;
    }

    function saveCategory(category: RhythmicGymnasticsCategory) {
        if (updateCategoryIndex !== -1) {
            props.onEdit(category, updateCategoryIndex);
        } else {
            props.onSave(category);
        }
        setDefaultCategoryValues({ ...defaultFormValues });
        setShowForm(false);
    }

    return (
        <>
            <h4
                style={{
                    textAlign: 'start',
                    marginTop: 32,
                }}
                ref={(el) => {
                    if (el) {
                        el.style.setProperty(
                            'margin-bottom',
                            '20px',
                            'important'
                        );
                    }
                }}
            >
                {t('words.categories')}
            </h4>
            {props.categories.length > 0 ? (
                <EventCategoriesTable
                    categories={props.categories}
                    onUpdate={(
                        i: number,
                        category: RhythmicGymnasticsCategory
                    ) => {
                        setUpdateCategoryIndex(i);
                        setDefaultCategoryValues({
                            ...category,
                        });
                        setShowForm(true);
                    }}
                    onDelete={(i: number) => props.onDelete(i)}
                />
            ) : null}
            {showForm ? (
                <div className="category-form">
                    <X
                        size={30}
                        style={{
                            display: 'block',
                            marginLeft: 'auto',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setShowForm(false);
                            setShowMoreApps(false);
                        }}
                    />
                    <Formik
                        initialValues={defaultCategoryValues}
                        validationSchema={schema}
                        validateOnChange={validateOnChange}
                        initialErrors={{}}
                        innerRef={formRef}
                        onSubmit={(values) => {
                            saveCategory(values);
                        }}
                    >
                        {({ handleSubmit, values, errors, setFieldValue }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="title">
                                        {t('words.categoryTitle')}*
                                    </Label>
                                    <Input
                                        id="title"
                                        name="title"
                                        placeholder={t('words.title')}
                                        type="text"
                                        value={values.title}
                                        onChange={(e) =>
                                            setFieldValue(
                                                'title',
                                                e.target.value
                                            )
                                        }
                                        invalid={!!errors.title}
                                    />
                                    <FormFeedback>{errors.title}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="type">{t('words.type')}*</Label>
                                    <BaseSelect
                                        title={t('words.type')}
                                        options={categoryTypeOptions}
                                        value={categoryTypeOptions.find(
                                            (x) => x.value === values.type
                                        )}
                                        onChange={(value: SelectOption) =>
                                            setFieldValue('type', value.value)
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="yearOfBirth">
                                        {t('words.yearOfBirth')}*
                                    </Label>
                                    <Input
                                        id="yearOfBirth"
                                        name="yearOfBirth"
                                        placeholder={t('words.yearOfBirth')}
                                        type="text"
                                        value={values.yearOfBirth}
                                        onChange={(e) =>
                                            setFieldValue(
                                                'yearOfBirth',
                                                e.target.value
                                            )
                                        }
                                        invalid={!!errors.yearOfBirth}
                                    />
                                    <FormFeedback>
                                        {errors.yearOfBirth}
                                    </FormFeedback>
                                </FormGroup>
                                {values.type === BaseCategoryType.Individual ? (
                                    <>
                                        <Label
                                            for=""
                                            style={{ marginBottom: 10 }}
                                        >
                                            {t('words.chooseApparatus')}
                                        </Label>
                                        <BaseSelect
                                            title={appNumberTitle(1)}
                                            options={individualAppsOptions}
                                            value={values.firstApp[0]}
                                            onChange={(value: SelectOption) =>
                                                setFieldValue('firstApp', [
                                                    {
                                                        value: value.value,
                                                        text: value.text,
                                                        icon: value.icon,
                                                        count: 1,
                                                    },
                                                ])
                                            }
                                        />
                                        <BaseSelect
                                            title={appNumberTitle(2)}
                                            options={individualAppsOptions}
                                            value={values.secondApp[0]}
                                            onChange={(value: SelectOption) =>
                                                setFieldValue('secondApp', [
                                                    {
                                                        value: value.value,
                                                        text: value.text,
                                                        icon: value.icon,
                                                        count: 1,
                                                    },
                                                ])
                                            }
                                        />
                                        <BaseSelect
                                            title={appNumberTitle(3)}
                                            options={individualAppsOptions}
                                            value={values.thirdApp[0]}
                                            onChange={(value: SelectOption) =>
                                                setFieldValue('thirdApp', [
                                                    {
                                                        value: value.value,
                                                        text: value.text,
                                                        icon: value.icon,
                                                        count: 1,
                                                    },
                                                ])
                                            }
                                        />
                                        {showMoreApps ? (
                                            <>
                                                <BaseSelect
                                                    title={appNumberTitle(4)}
                                                    options={
                                                        individualAppsOptions
                                                    }
                                                    value={values.fourthApp[0]}
                                                    onChange={(
                                                        value: SelectOption
                                                    ) =>
                                                        setFieldValue(
                                                            'fourthApp',
                                                            [
                                                                {
                                                                    value: value.value,
                                                                    text: value.text,
                                                                    icon: value.icon,
                                                                    count: 1,
                                                                },
                                                            ]
                                                        )
                                                    }
                                                />
                                                <BaseSelect
                                                    title={appNumberTitle(5)}
                                                    options={
                                                        individualAppsOptions
                                                    }
                                                    value={values.fifthApp[0]}
                                                    onChange={(
                                                        value: SelectOption
                                                    ) =>
                                                        setFieldValue(
                                                            'fifthApp',
                                                            [
                                                                {
                                                                    value: value.value,
                                                                    text: value.text,
                                                                    icon: value.icon,
                                                                    count: 1,
                                                                },
                                                            ]
                                                        )
                                                    }
                                                />
                                                <BaseSelect
                                                    title={appNumberTitle(6)}
                                                    options={
                                                        individualAppsOptions
                                                    }
                                                    value={values.sixthApp[0]}
                                                    onChange={(
                                                        value: SelectOption
                                                    ) =>
                                                        setFieldValue(
                                                            'sixthApp',
                                                            [
                                                                {
                                                                    value: value.value,
                                                                    text: value.text,
                                                                    icon: value.icon,
                                                                    count: 1,
                                                                },
                                                            ]
                                                        )
                                                    }
                                                />
                                                <BaseSelect
                                                    title={t('words.finalApp')}
                                                    options={
                                                        individualAppsOptions
                                                    }
                                                    value={values.finalApp[0]}
                                                    onChange={(
                                                        value: SelectOption
                                                    ) =>
                                                        setFieldValue(
                                                            'finalApp',
                                                            [
                                                                {
                                                                    value: value.value,
                                                                    text: value.text,
                                                                    icon: value.icon,
                                                                    count: 1,
                                                                },
                                                            ]
                                                        )
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <div
                                                className="more-apps-btn"
                                                onClick={() =>
                                                    setShowMoreApps(true)
                                                }
                                            >
                                                {t('words.moreApps')}
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Label
                                            for=""
                                            style={{ marginBottom: 10 }}
                                        >
                                            {t('words.chooseApparatus')}
                                        </Label>
                                        <AppartsGroupSelectComponent
                                            title={appNumberTitle(1)}
                                            value={
                                                defaultCategoryValues.firstApp
                                            }
                                            onChange={(
                                                value: RhythmicGymnasticsAppart[]
                                            ) =>
                                                setFieldValue('firstApp', value)
                                            }
                                        />
                                        <AppartsGroupSelectComponent
                                            title={appNumberTitle(2)}
                                            value={values.secondApp}
                                            onChange={(
                                                value: RhythmicGymnasticsAppart[]
                                            ) =>
                                                setFieldValue(
                                                    'secondApp',
                                                    value
                                                )
                                            }
                                        />
                                        <AppartsGroupSelectComponent
                                            title={appNumberTitle(3)}
                                            value={values.thirdApp}
                                            onChange={(
                                                value: RhythmicGymnasticsAppart[]
                                            ) =>
                                                setFieldValue('thirdApp', value)
                                            }
                                        />
                                        {showMoreApps ? (
                                            <>
                                                <AppartsGroupSelectComponent
                                                    title={appNumberTitle(4)}
                                                    value={values.fourthApp}
                                                    onChange={(
                                                        value: RhythmicGymnasticsAppart[]
                                                    ) =>
                                                        setFieldValue(
                                                            'fourthApp',
                                                            value
                                                        )
                                                    }
                                                />
                                                <AppartsGroupSelectComponent
                                                    title={appNumberTitle(5)}
                                                    value={values.fifthApp}
                                                    onChange={(
                                                        value: RhythmicGymnasticsAppart[]
                                                    ) =>
                                                        setFieldValue(
                                                            'fifthApp',
                                                            value
                                                        )
                                                    }
                                                />
                                                <AppartsGroupSelectComponent
                                                    title={appNumberTitle(6)}
                                                    value={values.sixthApp}
                                                    onChange={(
                                                        value: RhythmicGymnasticsAppart[]
                                                    ) =>
                                                        setFieldValue(
                                                            'sixthApp',
                                                            value
                                                        )
                                                    }
                                                />
                                                <AppartsGroupSelectComponent
                                                    title={t('words.finalApp')}
                                                    value={values.finalApp}
                                                    onChange={(
                                                        value: RhythmicGymnasticsAppart[]
                                                    ) =>
                                                        setFieldValue(
                                                            'finalApp',
                                                            value
                                                        )
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <div
                                                className="more-apps-btn"
                                                onClick={() =>
                                                    setShowMoreApps(true)
                                                }
                                            >
                                                {t('words.moreApps')}
                                            </div>
                                        )}
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                    <div className="category-submit-bar">
                        <Button
                            block
                            outline
                            color="primary"
                            onClick={submitForm}
                            style={{ marginTop: 24 }}
                        >
                            <Check2Square />
                            {t('phrases.saveCategory').toUpperCase()}
                        </Button>
                    </div>
                </div>
            ) : (
                <Button
                    block
                    outline
                    color="primary"
                    onClick={() => setShowForm(true)}
                    style={{ margin: '0 0 64px 0' }}
                >
                    <PlusSquare />
                    {t('phrases.addNewCategory').toUpperCase()}
                </Button>
            )}
        </>
    );
}
