import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    FormGroup,
    Label,
    Input,
    Button,
    Form,
    InputGroup,
    FormFeedback,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

import api from 'config/api';
import { regexHelper } from 'helpers/regexHelper';
import CountriesSelect from 'components/shared/CountriesSelect';
import PhoneInputField from 'components/shared/PhoneInputField';
import { UserRegisterModel } from 'config/api/models';
import CheckEmailPage from './CheckEmailPage';
import ProposalFooter from '../shared/ProposalFooter';
import { ResponseError } from 'constants/interfaces';
import globalStore from 'store/globalStore';
import { ApplicationState } from 'store';
import { useHistory } from 'react-router';

export default function RegistrationPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const userCountry = useSelector(
        (state: ApplicationState) => state.global?.countryName
    );
    const [showForm, setShowForm] = useState(true);
    const [isPassword, setIsPassword] = useState(true);
    const [isConfirmPassword, setIsConfirmPassword] = useState(true);
    const [validateOnChange, setValidateOnChange] = useState(false);

    const schema = Yup.object().shape({
        firstName: Yup.string()
            .min(1, t('errors.greaterZero', { name: t('words.firstName') }))
            .max(
                50,
                t('errors.maxLength', {
                    name: t('words.firstName'),
                    lenght: 50,
                })
            )
            .required(
                t('errors.required', {
                    name: t('words.firstName'),
                })
            )
            .matches(
                regexHelper.onlyLetters,
                t('errors.lettersOnly', {
                    name: t('words.firstName'),
                })
            ),
        lastName: Yup.string()
            .min(1, t('errors.greaterZero', { name: t('words.lastName') }))
            .max(50, t('errors.maxLength', { name: t('words.lastName') }))
            .required(
                t('errors.required', {
                    name: t('words.firstName'),
                })
            )
            .matches(
                regexHelper.onlyLetters,
                t('errors.lettersOnly', {
                    name: t('words.lastName'),
                })
            ),
        country: Yup.string().required(
            t('errors.required', {
                name: t('words.country'),
            })
        ),
        city: Yup.string()
            .required(
                t('errors.required', {
                    name: t('words.city'),
                })
            )
            .matches(
                regexHelper.onlyLetters,
                t('errors.lettersOnly', {
                    name: t('words.city'),
                })
            ),
        club: Yup.string().required(
            t('errors.required', {
                name: t('words.club'),
            })
        ),
        email: Yup.string()
            .email(t('errors.email'))
            .required(
                t('errors.required', {
                    name: t('words.email'),
                })
            ),
        //phoneNumber: Yup.string().required('Required'),
        password: Yup.string()
            .required(
                t('errors.required', {
                    name: t('words.password'),
                })
            )
            .matches(regexHelper.password, t('words.passwordRequirements')),
        confirmPassword: Yup.string()
            .required(
                t('errors.required', {
                    name: t('words.confirmPassword'),
                })
            )
            .oneOf([Yup.ref('password'), null], t('errors.passwordsMustMatch')),
    });

    return (
        <div className="row">
            {
                showForm ? (
                    <>
                        <h4>{t('words.registration')}</h4>
                        <p className="body-small text-center">
                            {t('phrases.createAccount')}
                        </p>

                        <Formik
                            initialValues={
                                {
                                    firstName: '',
                                    lastName: '',
                                    country: userCountry,
                                    city: '',
                                    club: '',
                                    email: '',
                                    phoneNumber: '',
                                    password: '',
                                    confirmPassword: '',
                                } as UserRegisterModel
                            }
                            validationSchema={schema}
                            validateOnChange={validateOnChange}
                            initialErrors={{}}
                            onSubmit={(values) => {
                                dispatch(
                                    globalStore.actionCreators.showLoader()
                                );
                                api.account
                                    .registerUser(values)
                                    // temporary
                                    //.then(() => setShowForm(false))
                                    .then(() => {
                                        history.push('auth/login');
                                        dispatch(
                                            globalStore.actionCreators.setMessage(
                                                {
                                                    status: 200,
                                                    title: '',
                                                    message: t(
                                                        'phrases.pleaseLogIn'
                                                    ),
                                                }
                                            )
                                        );
                                    })
                                    .catch((error: ResponseError) =>
                                        dispatch(
                                            globalStore.actionCreators.setMessage(
                                                error
                                            )
                                        )
                                    )
                                    .finally(() =>
                                        dispatch(
                                            globalStore.actionCreators.hideLoader()
                                        )
                                    );
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                setFieldValue,
                            }) => (
                                <Form
                                    onSubmit={handleSubmit}
                                    className="col-md-6 offset-md-3"
                                >
                                    <FormGroup>
                                        <Label for="lastName">
                                            {t('words.lastName')}
                                        </Label>
                                        <Input
                                            id="lastName"
                                            name="lastName"
                                            placeholder={t('words.lastName')}
                                            type="text"
                                            value={values.lastName}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    'lastName',
                                                    e.target.value
                                                )
                                            }
                                            invalid={!!errors.lastName}
                                        />
                                        <FormFeedback>
                                            {errors.lastName}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="firstName">
                                            {t('words.firstName')}
                                        </Label>
                                        <Input
                                            id="firstName"
                                            name="firstName"
                                            placeholder={t('words.firstName')}
                                            type="text"
                                            value={values.firstName}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    'firstName',
                                                    e.target.value
                                                )
                                            }
                                            invalid={!!errors.firstName}
                                        />
                                        <FormFeedback>
                                            {errors.firstName}
                                        </FormFeedback>
                                    </FormGroup>
                                    <CountriesSelect
                                        value={values.country}
                                        onChange={(e: string) =>
                                            setFieldValue('country', e)
                                        }
                                        error={!!errors.country}
                                    />
                                    <FormGroup>
                                        <Label for="city">
                                            {t('words.city')}
                                        </Label>
                                        <Input
                                            id="city"
                                            name="city"
                                            placeholder={t('words.city')}
                                            type="text"
                                            value={values.city}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    'city',
                                                    e.target.value
                                                )
                                            }
                                            invalid={!!errors.city}
                                        />
                                        <FormFeedback>
                                            {errors.city}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="club">
                                            {t('words.club')}
                                        </Label>
                                        <Input
                                            id="club"
                                            name="club"
                                            placeholder={t('words.club')}
                                            type="text"
                                            value={values.club}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    'club',
                                                    e.target.value
                                                )
                                            }
                                            invalid={!!errors.club}
                                        />
                                        <FormFeedback>
                                            {errors.club}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="email">
                                            {t('words.email')}
                                        </Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            placeholder={t('words.email')}
                                            type="text"
                                            value={values.email}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    'email',
                                                    e.target.value
                                                )
                                            }
                                            invalid={!!errors.email}
                                        />
                                        <FormFeedback>
                                            {errors.email}
                                        </FormFeedback>
                                    </FormGroup>
                                    <PhoneInputField
                                        isRequired={false}
                                        value={values.phoneNumber}
                                        onChange={(e: string) =>
                                            setFieldValue('phoneNumber', e)
                                        }
                                        error={!!errors.phoneNumber}
                                        country={values.country}
                                    />
                                    <FormGroup>
                                        <Label for="password">
                                            {t('words.password')}
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                name="password"
                                                placeholder={t(
                                                    'words.password'
                                                )}
                                                type={
                                                    isPassword
                                                        ? 'password'
                                                        : 'text'
                                                }
                                                value={values.password}
                                                style={{
                                                    backgroundImage: 'none',
                                                }}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'password',
                                                        e.target.value
                                                    )
                                                }
                                                invalid={!!errors.password}
                                            />
                                            <FormFeedback>
                                                {errors.password}
                                            </FormFeedback>
                                            <div
                                                className="input-group-text"
                                                onClick={() =>
                                                    setIsPassword(!isPassword)
                                                }
                                            >
                                                {isPassword ? (
                                                    <Eye />
                                                ) : (
                                                    <EyeSlash />
                                                )}
                                            </div>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="confirmPassword">
                                            {t('words.confirmPassword')}
                                        </Label>
                                        <InputGroup>
                                            <Input
                                                name="confirmPassword"
                                                placeholder={t(
                                                    'words.confirmPassword'
                                                )}
                                                type={
                                                    isConfirmPassword
                                                        ? 'password'
                                                        : 'text'
                                                }
                                                value={values.confirmPassword}
                                                style={{
                                                    backgroundImage: 'none',
                                                }}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'confirmPassword',
                                                        e.target.value
                                                    )
                                                }
                                                invalid={
                                                    !!errors.confirmPassword
                                                }
                                            />
                                            <FormFeedback>
                                                {errors.confirmPassword}
                                            </FormFeedback>
                                            <div
                                                className="input-group-text"
                                                onClick={() =>
                                                    setIsConfirmPassword(
                                                        !isConfirmPassword
                                                    )
                                                }
                                            >
                                                {isConfirmPassword ? (
                                                    <Eye />
                                                ) : (
                                                    <EyeSlash />
                                                )}
                                            </div>
                                        </InputGroup>
                                    </FormGroup>

                                    <p
                                        className="fs-14"
                                        style={{ marginBottom: 0 }}
                                    >
                                        {t('words.passwordRequirements')}
                                    </p>
                                    <Button
                                        block
                                        color="primary"
                                        type="submit"
                                        onClick={() =>
                                            setValidateOnChange(true)
                                        }
                                    >
                                        {t('words.register')}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                        <ProposalFooter
                            text={t('phrases.alreadyHaveAccount')}
                            link={t('phrases.login')}
                            goTo="login"
                        />
                    </>
                ) : null //temporary
                //<CheckEmailPage />
            }
        </div>
    );
}
