import * as React from 'react';
import { Route, Switch } from 'react-router';

import NewEventPage from 'components/events/newEvent/NewEventPage';
import NotFoundPage from './NotFoundPage';
import Home from 'components/Home';
import EventsPage from 'components/events/EventsPage';
import EventDetailsPage from 'components/events/eventDetails/EventDetailsPage';
import SingleRegistrationPage from 'components/events/registration/SingleRegistrationPage';
import GroupRegistrationPage from 'components/events/registration/GroupRegistrationPage';
import ParticipiantEditPage from 'components/events/components/ParticipiantEditPage';
import PrivacyPolicy from 'components/policies/PrivacyPolicy';
import UserEventsPage from 'components/events/userEvents/UserEventsPage';
import UserEventDetailsPage from 'components/events/userEvents/UserEventDetailsPage';

export default function AdminRouter() {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/events" component={EventsPage} />
            <Route path="/events/create" component={NewEventPage} />
            <Route
                path="/events/:id/registration"
                component={SingleRegistrationPage}
            />
            <Route
                path="/events/:id/group-registration"
                component={GroupRegistrationPage}
            />
            <Route
                path="/events/:id/participiants/:participiantId"
                component={ParticipiantEditPage}
            />
            <Route path="/events/:id" component={EventDetailsPage} />
            <Route exact path="/my-events" component={UserEventsPage} />
            <Route path="/my-events/:id" component={UserEventDetailsPage} />
            <Route path="*" component={NotFoundPage} />
        </Switch>
    );
}
