import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actionCreators } from 'store/eventsStore/actionCreators';
import { ApplicationState } from 'store';
import EventComponent from '../components/EventComponent';

export default function UserEventsPage() {
    const events = useSelector(
        (state: ApplicationState) => state.events?.events
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionCreators.getUserEvents());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {events && events?.length > 0 ? (
                events?.map((event) => (
                    <EventComponent
                        {...{ ...event, isDraft: true }}
                        key={event.id}
                    />
                ))
            ) : (
                <span>no events</span>
            )}
        </>
    );
}
