import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

export default function GuestNavMenu(props: { handleClose?: () => void }) {
    const { t } = useTranslation();
    const location = useLocation();
    function close() {
        props.handleClose && props.handleClose();
    }

    return (
        <>
            <LanguageSwitcher />
            <NavItem key="login" className="d-grid gap-2 w-100">
                <NavLink
                    tag={Link}
                    className="text-white btn btn-primary btn-lg btn-block "
                    to={`/auth/login?returnUrl=${location.pathname}`}
                    onClick={() => close()}
                >
                    {t('words.login')}
                </NavLink>
            </NavItem>
            <NavItem className="w-100">
                <NavLink
                    tag={Link}
                    className="text-primary btn btn-outline-primary btn-lg btn-block"
                    to="/registration"
                    onClick={() => close()}
                >
                    {t('words.register')}
                </NavLink>
            </NavItem>
        </>
    );
}
