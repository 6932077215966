import { Action, Reducer } from 'redux';
import { GlobalActions } from './actions';
import { ResponseError } from 'constants/interfaces';

export interface GlobalState {
    countryName: string;
    isLoading: boolean;
    message: ResponseError;
}

export const defaultMessage: ResponseError = {
    status: 0,
    title: '',
    message: '',
};

const defaultState: GlobalState = {
    countryName: '',
    isLoading: false,
    message: defaultMessage,
};

export const reducer: Reducer<GlobalState> = (
    state: GlobalState | undefined,
    incomingAction: Action
): GlobalState => {
    if (state === undefined) {
        return defaultState;
    }

    const action = incomingAction as GlobalActions;
    switch (action.type) {
        case 'GLOBAL_SHOW_LOADER':
            return {
                ...state,
                isLoading: true,
            };
        case 'GLOBAL_HIDE_LOADER':
            return {
                ...state,
                isLoading: false,
            };
        case 'GLOBAL_SET_MESSAGE':
            return {
                ...state,
                message: action.payload,
            };
        case 'GLOBAL_CLEAR_MESSAGE':
            return {
                ...state,
                message: defaultMessage,
            };
        case 'GLOBAL_GET_COUNTRY_SUCCESS':
            return {
                ...state,
                countryName: action.payload,
            };
        default:
            return state;
    }
};
