import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Layout from 'components/Layout';
import MainRoute from 'routes/MainRouter';
import { actionCreators } from 'store/accountStore/actionCreators';
import globalStore from 'store/globalStore';
import './style.scss';
import { ApplicationState } from 'store';

export default () => {
    const user = useSelector((state: ApplicationState) => state.account);
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(actionCreators.getUserData());
        dispatch(globalStore.actionCreators.getLocationCountry());
        // eslint-disable-next-line
    }, []);
    return (
        <Layout>
            <MainRoute role={user?.role} />
        </Layout>
    );
};
