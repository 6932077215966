import { AccountActions } from './actions';
import { AppThunkAction } from '..';
import api from 'config/api';
import {
    UserEmailLoginModel,
    UserPhoneLoginModel,
    SSOModel,
} from 'config/api/models';
import { AccountState } from './reducer';
import { history } from '../../index';
import { t } from 'i18next';

export const actionCreators = {
    getUserData: (): AppThunkAction<AccountActions> => (dispatch) => {
        //dispatch({ type: 'GLOBAL_SHOW_LOADER' });
        dispatch({ type: 'ACCOUNT_GET_DATA_START' });
        api.account
            .getData()
            .then((response) =>
                dispatch({
                    type: 'ACCOUNT_GET_DATA_SUCCESS',
                    payload: response,
                })
            )
            .catch((error) => {
                dispatch({ type: 'ACCOUNT_GET_DATA_ERROR' });
                dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
            });
        //.finally(() => dispatch({ type: 'GLOBAL_HIDE_LOADER' }));
    },
    setUserData:
        (model: AccountState): AppThunkAction<AccountActions> =>
        (dispatch) => {
            dispatch({ type: 'ACCOUNT_SET_DATA', payload: model });
        },
    logIn:
        (
            model: UserEmailLoginModel,
            returnUrl: string | null = null
        ): AppThunkAction<AccountActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({ type: 'ACCOUNT_LOG_IN_START' });
            api.account
                .logInUser(model)
                .then((response) => {
                    dispatch({
                        type: 'ACCOUNT_LOG_IN_SUCCESS',
                        payload: response,
                    });
                    history.push(returnUrl || '/');
                    dispatch({
                        type: 'GLOBAL_SET_MESSAGE',
                        payload: {
                            status: 200,
                            title: '',
                            message: t('phrases.succesfullyLoggedIn'),
                        },
                    });
                })
                .catch((error) => {
                    dispatch({ type: 'ACCOUNT_LOG_IN_ERROR' });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => {
                    dispatch({ type: 'GLOBAL_HIDE_LOADER' });
                });
        },
    logInWithPhone:
        (
            model: UserPhoneLoginModel,
            returnUrl: string | null = null
        ): AppThunkAction<AccountActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({ type: 'ACCOUNT_LOG_IN_START' });
            api.account
                .logInUserWithPhoneNumber(model)
                .then((response) => {
                    dispatch({
                        type: 'ACCOUNT_LOG_IN_SUCCESS',
                        payload: response,
                    });
                    history.push(returnUrl || '/');
                    dispatch({
                        type: 'GLOBAL_SET_MESSAGE',
                        payload: {
                            status: 200,
                            title: '',
                            message: t('phrases.succesfullyLoggedIn'),
                        },
                    });
                })
                .catch((error) => {
                    dispatch({ type: 'ACCOUNT_LOG_IN_ERROR' });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => {
                    dispatch({ type: 'GLOBAL_HIDE_LOADER' });
                });
        },
    logInSSO:
        (
            model: SSOModel,
            returnUrl: string | null = null
        ): AppThunkAction<AccountActions> =>
        (dispatch) => {
            dispatch({ type: 'GLOBAL_SHOW_LOADER' });
            dispatch({ type: 'ACCOUNT_LOG_IN_START' });
            api.account
                .sSO(model)
                .then((response) => {
                    dispatch({
                        type: 'ACCOUNT_LOG_IN_SUCCESS',
                        payload: response,
                    });
                    history.push(returnUrl || '/');
                    dispatch({
                        type: 'GLOBAL_SET_MESSAGE',
                        payload: {
                            status: 200,
                            title: '',
                            message: t('phrases.succesfullyLoggedIn'),
                        },
                    });
                })
                .catch((error) => {
                    dispatch({ type: 'ACCOUNT_LOG_IN_ERROR' });
                    dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
                })
                .finally(() => {
                    dispatch({ type: 'GLOBAL_HIDE_LOADER' });
                });
        },
    logOut: (): AppThunkAction<AccountActions> => (dispatch) => {
        dispatch({ type: 'ACCOUNT_LOG_OUT_START' });
        api.account
            .logOutUser()
            .catch((error) => {
                dispatch({ type: 'ACCOUNT_LOG_OUT_ERROR' });
                dispatch({ type: 'GLOBAL_SET_MESSAGE', payload: error });
            })
            .then(() => dispatch({ type: 'ACCOUNT_LOG_OUT_SUCCESS' }));
    },
};
