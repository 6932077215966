import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    Form,
    FormGroup,
    Input,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Funnel } from 'react-bootstrap-icons';

import { actionCreators } from 'store/eventsStore/actionCreators';
import { ApplicationState } from 'store';
import EventComponent from './components/EventComponent';
import { UserRole } from 'constants/enums';
import { EventTypeFilter } from 'config/api/models';
import images from 'assets/images';
import { Formik } from 'formik';
import CountriesSelect from 'components/shared/CountriesSelect';

interface EventsFilter {
    dateFrom: string | undefined;
    dateTo: string | undefined;
    country: string;
    city: string;
}

export default function EventsPage() {
    const events = useSelector((state: ApplicationState) => state.events);
    const user = useSelector((state: ApplicationState) => state.account);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [eventsType, setEventsType] = useState<EventTypeFilter>('all');
    const [showFilters, setShowFilters] = useState(false);
    const formRef = useRef(null);

    const closeBtn = (
        <img
            src={images.icons.noApp}
            alt="close-modal"
            onClick={() => setShowFilters(false)}
            style={{ cursor: 'pointer' }}
        />
    );

    function submitForm() {
        if (formRef.current) {
            setShowFilters(false);
            // @ts-ignore
            formRef.current.handleSubmit();
        }
    }

    function cleanForm() {
        if (formRef.current) {
            filterValues.current = defaultFormValues;
            // @ts-ignore
            formRef.current.resetForm(defaultFormValues);
        }
    }

    const defaultFormValues: EventsFilter = {
        dateFrom: undefined,
        dateTo: undefined,
        country: '',
        city: '',
    };

    const filterValues = useRef<EventsFilter>(defaultFormValues);

    function getEvents() {
        if (user?.role === UserRole.ADMIN && events?.showNotPublished) {
            dispatch(
                actionCreators.getNotPublishedEvents(
                    eventsType,
                    filterValues.current.dateFrom,
                    filterValues.current.dateTo,
                    filterValues.current.country,
                    filterValues.current.city
                )
            );
        } else {
            dispatch(
                actionCreators.getEvents(
                    eventsType,
                    filterValues.current.dateFrom,
                    filterValues.current.dateTo,
                    filterValues.current.country,
                    filterValues.current.city
                )
            );
        }
    }

    function addMockElements() {
        if (
            window.matchMedia('(min-width: 1200px)') &&
            events &&
            events?.events.length > 0
        ) {
            const needMocks = 3 - (events?.events.length % 3);
            console.log(needMocks);
            let result: any[] = [];
            for (let n = 0; n < needMocks; n++) {
                result.push(
                    <div
                        style={{ visibility: 'hidden' }}
                        key={events?.events[0].id + n}
                        className="fake-list-container"
                    >
                        <EventComponent
                            {...{ ...events?.events[0], isDraft: false }}
                        />
                    </div>
                );
            }

            return result;
        }
    }

    useEffect(() => {
        getEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events?.showNotPublished, eventsType]);

    return (
        <>
            <Form>
                <FormGroup check inline>
                    <Input
                        type="radio"
                        name="events"
                        checked={eventsType === 'all'}
                        onChange={() => setEventsType('all')}
                    />
                    <Label check>{t('words.all')}</Label>
                </FormGroup>
                <FormGroup check inline>
                    <Input
                        type="radio"
                        name="events"
                        checked={eventsType === 'live'}
                        onChange={() => setEventsType('live')}
                    />
                    <Label check>{t('words.live')}</Label>
                </FormGroup>
                <FormGroup check inline>
                    <Input
                        type="radio"
                        name="events"
                        checked={eventsType === 'virtual'}
                        onChange={() => setEventsType('virtual')}
                    />
                    <Label check>{t('words.virtual')}</Label>
                </FormGroup>
            </Form>
            {user?.role === UserRole.ADMIN && (
                <>
                    <div className="separator-line"></div>
                    <Form>
                        <FormGroup check inline>
                            <Input
                                type="radio"
                                name="events"
                                checked={!events?.showNotPublished}
                                onChange={() =>
                                    dispatch(
                                        actionCreators.setNotPublishedEvents(
                                            false
                                        )
                                    )
                                }
                            />
                            <Label check>{t('words.published')}</Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Input
                                type="radio"
                                name="events"
                                checked={events?.showNotPublished}
                                onChange={() =>
                                    dispatch(
                                        actionCreators.setNotPublishedEvents(
                                            true
                                        )
                                    )
                                }
                            />
                            <Label check>{t('words.notPublished')}</Label>
                        </FormGroup>
                    </Form>
                </>
            )}
            <div
                className="d-flex justify-content-end"
                style={{ margin: '20px 0 32px 0' }}
            >
                <Button
                    color="primary"
                    type="button"
                    onClick={() => setShowFilters(true)}
                >
                    <Funnel />
                    {t('words.filter').toUpperCase()}
                </Button>
            </div>
            <Modal
                isOpen={showFilters}
                toggle={() => setShowFilters(false)}
                centered
            >
                <ModalHeader
                    toggle={() => setShowFilters(false)}
                    close={closeBtn}
                ></ModalHeader>
                <ModalBody style={{ textAlign: 'start' }}>
                    <p
                        style={{
                            textAlign: 'start',
                            fontWeight: 700,
                            marginBottom: 20,
                        }}
                    >
                        {t('phrases.chooseEventsFilters')}
                    </p>
                    <Formik
                        initialValues={filterValues.current}
                        initialErrors={{}}
                        innerRef={formRef}
                        onSubmit={async (values) => {
                            filterValues.current = values;
                            getEvents();
                        }}
                    >
                        {({ handleSubmit, values, errors, setFieldValue }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="dateFrom">
                                        {t('words.startDate')}
                                    </Label>
                                    <Input
                                        id="dateFrom"
                                        name="dateFrom"
                                        type="date"
                                        value={values.dateFrom}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'dateFrom',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="dateTo">
                                        {t('words.endDate')}
                                    </Label>
                                    <Input
                                        id="dateTo"
                                        name="dateTo"
                                        type="date"
                                        value={values.dateTo}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'dateTo',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </FormGroup>
                                <CountriesSelect
                                    value={values.country}
                                    onChange={(e: string) =>
                                        setFieldValue('country', e)
                                    }
                                    error={!!errors.country}
                                />
                                <FormGroup>
                                    <Label for="city">{t('words.city')}</Label>
                                    <Input
                                        id="city"
                                        name="city"
                                        placeholder={t('words.city')}
                                        type="text"
                                        value={values.city}
                                        onChange={(e) => {
                                            setFieldValue(
                                                'city',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </FormGroup>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
                <ModalFooter>
                    <Button
                        outline
                        className="btn-inline"
                        color="secondary"
                        onClick={cleanForm}
                    >
                        {t('words.clear')}
                    </Button>
                    <Button
                        className="btn-inline"
                        color="primary"
                        onClick={submitForm}
                    >
                        {t('words.show')}
                    </Button>{' '}
                </ModalFooter>
            </Modal>

            {events && events?.events.length > 0 ? (
                <div className="main-events-list-container">
                    {events?.events.map((event) => (
                        <EventComponent
                            {...{ ...event, isDraft: false }}
                            key={event.id}
                        />
                    ))}
                    {addMockElements()}
                </div>
            ) : (
                <span>no events</span>
            )}
        </>
    );
}
